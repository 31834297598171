import { Button, Col, Divider, Form, Input, Row, Select, Space, Upload } from "antd"
import React from "react"
import resourceStore from "../../stores/resourceStore"
import { observer } from "mobx-react"
import permissionStore from "../../stores/permissionStore"
import { exampleResources } from "../../components/json/snippets"
import JsonSnippet from "../../components/json/JsonSnippet"

class CreateResourcePage extends React.Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            form: {},
            loading: false,
        }
    }

    componentDidMount() {
        permissionStore.getPermissions()
    }

    onSubmitPressed = () => {
        this.setState({ loading: true })
        resourceStore.createResource(this.state.form)
    }

    componentDidUpdate() {
        if (resourceStore.resourceFormState?.success) {
            this.setState({
                form: {},
                loading: resourceStore.resourceFormState?.loading,
            })
            resourceStore.cleanResourceFormState()
            this.formRef.current.resetFields()
        }

        if (resourceStore.resourceFormState?.success == false) {
            this.setState({
                loading: resourceStore.resourceFormState?.loading,
            })
            resourceStore.cleanResourceFormState()
        }
    }

    renderForm = () => {
        return (
            <>
                <Form ref={this.formRef} layout="vertical">
                    <Row gutter={[20, 20]}>
                        <Col span={8}>
                            <Form.Item
                                name={"method"}
                                label={"Μέθοδος"}
                                rules={[
                                    {
                                        message: "Παρακαλώ επιλέξτε μία μέθοδος",
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) =>
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                method: value,
                                            },
                                        })
                                    }

                                >
                                    {resourceStore.methods?.map((option) => {
                                        return (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={"name"}
                                label={"Ονομασία"}
                                rules={[
                                    {
                                        message: "Παρακαλώ συμπληρώστε την ονομασία του περιορισμού",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                name: e.target.value,
                                            },
                                        })
                                    }}
                                    placeholder={""}
                                    disabled={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name={"url"}
                                label={"URL"}
                                rules={[
                                    {
                                        message: "Παρακαλώ συμπληρώστε το URL",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                endpoint: e.target.value,
                                            },
                                        })
                                    }}
                                    placeholder={""}
                                    disabled={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name={"displayName"}
                                label={"Περιγραφή λειτουργίας"}
                                rules={[
                                    {
                                        message: "Παρακαλώ συμπληρώστε την λειτουργία",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                displayName: e.target.value,
                                            },
                                        })
                                    }}
                                    placeholder={""}
                                    disabled={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                name={"requiredPermissions"}
                                label={"Περιορισμοί"}
                                rules={[
                                    {
                                        message: "Παρακαλώ επιλέξτε περιορισμό",
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    onChange={(value) =>
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                requiredPermissions: value,
                                            },
                                        })
                                    }

                                >
                                    {permissionStore.permissionsOptions?.map((option) => {
                                        return (
                                            <Select.Option key={option.value} value={option.value}>
                                                {option.name}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    customRequestImplementation = async ({
        file,
        onSuccess,
        onError,
        onProgress,
    }) => {
        resourceStore.createResourceByFile(
            file, onSuccess, onError, onProgress
        )
    }


    render() {
        return (
            <>
                <Divider>Δημιουργία Πόρου</Divider>

                {this.renderForm()}
                <div style={{ display: "flex", gap: "16px" }}>
                    <Button key="ok" type="primary"
                        nothing2={permissionStore.permissionsOptions}
                        nothing={resourceStore.resourceFormState?.loading ? true : false}
                        loading={this.state.loading} style={{ width: "100%" }} onClick={() => this.onSubmitPressed()}>
                        Δημιουργία πόρου
                    </Button>
                    <Button
                        loading={this.state.loading}
                        style={{ width: "100%" }}
                        onClick={() => resourceStore.resourceFormState = {
                            success: true,
                            loading: false,
                        }}>
                        Kαθαρισμός Φόρμας
                    </Button>
                </div>
                <Row style={{ marginTop: "24px", width: '100%' }}>

                    <Divider>Δημιουργία πόρων μέσω αρχείου</Divider>
                    <Col span={12}>
                        <Upload style={{ width: "100%" }}

                            {...{
                                showUploadList: {
                                    showDownloadIcon: false,
                                    showRemoveIcon: false,
                                    previewIcon: false,
                                },
                                customRequest: this.customRequestImplementation,
                                onChange: (info) => {
                                    console.log(info)
                                },
                            }}
                        >
                            <Button key="ok" type="primary" style={{ width: "100%" }}>
                                Μεταφορτώστε αρχείο JSON
                            </Button>
                        </Upload>
                    </Col>
                    <Col span={12}>
                        <Divider>Υπόδειγμα αρχείου JSON</Divider>
                        <JsonSnippet json={exampleResources} />
                    </Col>
                </Row>


            </>
        )
    }
}

export default observer(CreateResourcePage)

import { Col, Divider, Image, Menu, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import React from "react";

import {
  LogoutOutlined,
  BankOutlined,
  PaperClipOutlined,
  UserSwitchOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  SnippetsOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  ExceptionOutlined,
  ApiOutlined,
  IdcardOutlined,
  SlidersOutlined,
  ContainerOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import {
  findMenuFromSubMenuChildKey,
  lowerCaseAllWordsExceptFirstLetters,
  mapLocationToMenuKey,
} from "../utils/generic";
import { primaryColor } from "../colors";
import moment from "moment";
import { GOV_GR_LOGO, PLATFORM_NAME, FUNDING_IMAGE } from "../config";
import Header from "./Header";
import authStore from "../stores/authStore";
import { UserRole } from "../utils/userRole";
import manualStore from "../stores/manualStore";
import ManualDrawer from "./manual/ManualDrawer";
const { SubMenu } = Menu;

class MainMenu extends React.Component {
  constructor(props) {
    super(props)

    manualStore.getManuals()

    this.state = {
      selectedMenu: findMenuFromSubMenuChildKey(this.headerItems(true), mapLocationToMenuKey()),
      showManuals: false
    }
  }

  onManualPressed = () => {
    this.setState({ showManuals: true })
  }

  onLogoutPressed = () => {
    authStore.onUserlogout()
  }

  onMenuItemChanged = (key) => {
    const itemKeysToIngore = ['help-menu']

    if (!itemKeysToIngore.includes(key)) {
      this.setState({ selectedMenu: key })
    }
  }

  headerItems = (all = false) => {
    const logoutItem = {
      danger: true,
      key: "logout",
      label: "Αποσύνδεση",
      icon: <LogoutOutlined />,
      onClick: () => this.onLogoutPressed(),
    };

    const adminItem = {
      key: "admin-menu",
      label: "Διαχείριση",
      icon: <SlidersOutlined />,
      children: [
        // {
        //     key: "organizations",
        //     label: <NavLink to="/organizations">Φορέων</NavLink>,
        //     icon: <BankOutlined />,
        // },
        {
          key: "clients",
          label: <NavLink to="/clients">Εφαρμογές</NavLink>,
          icon: <AppstoreOutlined />,
        },
      ],
    };

    const userItem = {
      key: "user-menu",
      label: "Χρήστες",
      icon: <UserOutlined />,
      children: [
        {
          key: "users",
          label: <NavLink to="/users">Χρήστες</NavLink>,
          icon: <UserOutlined />,
        },
        {
          key: "create-user",
          label: <NavLink to="/create-user">Δημιουργία χρήστη</NavLink>,
          icon: <PlusCircleOutlined />,
        },
      ],
    };

    const roleItem = {
      key: "role-menu",
      label: "Ρόλοι",
      icon: <TeamOutlined />,
      children: [
        {
          key: "roles",
          label: <NavLink to="/roles">Ρόλοι</NavLink>,
          icon: <TeamOutlined />,
        },
        {
          key: "create-role",
          label: <NavLink to="/create-role">Δημιουργία ρόλου</NavLink>,
          icon: <PlusCircleOutlined />,
        },
      ],
    };

    const organizationItem = {
      key: "organization-menu",
      label: "Φορείς",
      icon: <ContainerOutlined />,
      children: [
        {
          key: "application-progress",
          label: <NavLink to="/application-progress">Εξέλιξη αιτήσεων</NavLink>,
          icon: <FileDoneOutlined />,
        },
        {
          key: "organization-apply",
          label: <NavLink to="/organization-apply">Αίτηση δημιουργίας</NavLink>,
          icon: <PlusCircleOutlined />,
        },
        {
          key: "organization-user-management",
          label: <NavLink to="/organization-user-management">Διαχείριση χρηστών</NavLink>,
          icon: <UserSwitchOutlined />,
        },
        {
          key: "organization-users",
          label: <NavLink to="/organization-users">Χρήστες</NavLink>,
          icon: <UserOutlined />,
        },
      ],
    };

    const permissionItem = {
      key: "permission-menu",
      label: "Δικαιώματα",
      icon: <IdcardOutlined />,
      children: [
        {
          key: "permissions",
          label: <NavLink to="/permissions">Κανόνες περιορισμού</NavLink>,
          icon: <ExclamationCircleOutlined />,
        },
        {
          key: "create-permission",
          label: (
            <NavLink to="/create-permission">
              Δημιουργία κανόνα περιορισμού
            </NavLink>
          ),
          icon: <PlusCircleOutlined />,
        },
      ],
    };

    const resourceItem = {
      key: "resource-menu",
      label: "Πόροι",
      icon: <ApiOutlined />,
      children: [
        {
          key: "resources",
          label: <NavLink to="/resources">Πόροι</NavLink>,
          icon: <ExceptionOutlined />,
        },
        {
          key: "create-resource",
          label: <NavLink to="/create-resource">Δημιουργία πόρου</NavLink>,
          icon: <PlusCircleOutlined />,
        },
      ],
    };

    const reportsItem = {
      key: "reports-menu",
      label: "Αναφορές",
      icon: <PaperClipOutlined />,
      children: [
        {
          key: "sessions",
          label: <NavLink to="/sessions">Συνεδριάσεις</NavLink>,
          icon: <PaperClipOutlined />,
        },
      ],
    };

    const helpItem = {
      key: "help-menu",
      label: "Βοήθεια",
      icon: <QuestionCircleOutlined />,
      children: [
        {
          key: "manual",
          label: "Εγχειρίδια Χρήσης",
          icon: <SnippetsOutlined />,
          onClick: this.onManualPressed,
        },
      ],
    };

    const menuItems = [
      adminItem,
      userItem,
      roleItem,
      organizationItem,
      permissionItem,
      resourceItem,
      reportsItem,
    ];

    const defaultItems = [helpItem, logoutItem]

    if (all) return menuItems

    const role = authStore.role
    if (role === UserRole.SDX_ADMIN) {
      /**
       * SDX Admin
       * can see all items except "organization-apply"
       */
      return [
        ...menuItems.map((item) => {
          if (item.key === "organization-menu") {
            return {
              ...item,
              children: item.children.filter(child => child.key !== "organization-apply")
            };
          }
          return item
        }),
        ...defaultItems,
      ];
    }

    if (role.endsWith(UserRole.ORGANIZATION_ADMIN)) {
      /**
       * Organization Admin
       * can only see organization-related items
       */
      return [
        organizationItem,
        ...defaultItems,
      ]
    }

    if (role.endsWith(UserRole.ORGANIZATION_EMPLOYEE)) {
      /**
       * Organization Employee
       * can only see only help and logout
       */
      return defaultItems
    }

    if (role === UserRole.NO_ROLE) {
      return [
        ...[organizationItem].map((item) => {
          if (item.key === "organization-menu") {
            return {
              ...item,
              children: item.children.filter(child => child.key !== "organization-user-management" && child.key !== "organization-users")
            };
          }
          return item
        }),
        ...defaultItems,
      ]
    }

    // If role does not match any known roles, return only default items
    return defaultItems
  }

  render() {
    return (
      <>
        {this.state.showManuals ?
          <ManualDrawer
            manuals={manualStore.manuals}
            close={() => this.setState({ showManuals: false })}
          />
          : <></>
        }

        <Row
          style={{
            top: 0,
            zIndex: 1000,
            display: "flex",
          }}
        >
          <Col span={4} style={{ height: "inherit", display: "flex" }}>
            <Menu
              theme="dark"
              style={{
                borderRadius: 0,
                flexGrow: 1,
                minHeight: "100vh",
              }}
              selectedKeys={mapLocationToMenuKey()}
              mode="inline"
            // defaultOpenKeys={["admin-menu", "permission-menu", "resource-menu", "help-menu"]}
            >
              <Image
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  marginLeft: "10%",
                  paddingRight: "20%",
                  //   ...LOGO_STYLES,
                }}
                src={GOV_GR_LOGO}
                preview={false}
              />
              <div
                style={{
                  alignSelf: "center",
                  marginBottom: "20%",
                  marginTop: "5%",
                  marginLeft: "10%",
                }}
              >
                {PLATFORM_NAME}
              </div>

              {this.headerItems().map((item) =>
                item.children ? (
                  <SubMenu key={item.key} icon={item.icon} title={item.label} onClick={() => this.onMenuItemChanged(item.key)}>
                    {item.children.map((child) => (
                      <Menu.Item
                        icon={child.icon}
                        key={child.key}
                        onClick={child.onClick}
                        danger={item.danger}
                      >
                        {child.label}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={item.onClick}
                    danger={item.danger}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
              <div
                style={{
                  marginBottom: "8px",
                  color: "white",
                  position: "absolute",
                  bottom: 16,
                  marginLeft: "10%",
                }}
              >
                {/* <a target="_blank" rel="noreferrer" href={COMPANY_SITE}>
                  {COMPANY_FULL_NAME}
                </a> */}
              </div>
            </Menu>
          </Col>
          <Col span={20}>
            <Row style={{ width: "100%" }}>
              <Col
                style={{
                  background: "whitesmoke",
                }}
                span={24}
              >
                <Header selectedMenu={this.state.selectedMenu} />
              </Col>
              <Col span={24}>
                <Content
                  style={{ margin: "2%", flexGrow: 1, minHeight: "80vh", marginBottom: "5%" }}
                >
                  {this.props.children}
                  <Divider style={{ marginTop: '15vh', borderColor: 'transparent' }} />
                </Content>
              </Col>
            </Row>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                background: "whitesmoke",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              <div style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Row align={'center'}>
                  <Col span={24}>
                    {/* <p style={{ textAlign: 'center' }}>Με την χρηματοδότηση</p> */}
                    <Image
                      style={{
                        marginTop: '1.5%',
                        marginLeft: '20vw',
                        width: '40vw'
                      }}

                      src={FUNDING_IMAGE}
                      preview={false}
                    />
                  </Col>
                  <Col span={24}>
                    <p style={{ marginBottom: "0.4%", textAlign: "center", marginTop: '1%' }}>
                      © Copyright ΟΠΕΚΕΠΕ {moment().year()} - Υλοποίηση από την{" "}
                      <a href="https://cognitera.gr">Cognitera A.E.</a>
                    </p>
                  </Col>
                </Row>

              </div>

            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const styles = {
  userDetailsStyle: {
    color: "white",
    marginLeft: "5%",
  },
  logoStyle: {
    color: primaryColor,
    marginLeft: "3%",
    marginTop: "1.5%",
  },
};

export default MainMenu;

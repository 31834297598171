import React from 'react'
import TableWithLoader from 'antd-table-loader'
import { Divider, Input, Row, Skeleton } from 'antd'
import { observer } from 'mobx-react'
import { v4 } from 'uuid'
import clientStore from '../stores/clientStore'
import ClientDetailsModel from '../components/ClientDetailsModel'
import { primaryColor } from '../colors'

class ClientPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedClientModal: null,
      searchText: '',
    }
  }

  componentDidMount() {
    clientStore.getClients()
  }

  columns = [
    {
      title: 'ID εφαρμογής',
      align: 'center',
      dataIndex: 'clientId',
      key: 'client',
    },
    {
      title: 'Ονομασία',
      align: 'center',
      dataIndex: 'clientId',
      key: 'client',
    },
    {
      title: 'Περιγραφή',
      align: 'left',
      dataIndex: 'description',
      key: 'name',
    },
  ]

  onSearch = (e) => {
    const searchTerm = e.target.value
    this.setState({ searchText: searchTerm })
    clientStore.onSearch(searchTerm)
  }

  render() {
    return (
      <>
        {this.state.selectedClientModal ? (
          <ClientDetailsModel
            close={() => this.setState({ selectedClientModal: null })}
            client={this.state.selectedClientModal}
          />
        ) : (
          <></>
        )}
        <Divider>Εφαρμογές</Divider>
        <Row align={'center'} style={{ marginTop: '2%', marginBottom: '2%' }}>
          <Input.Search
            style={{ width: '40%', textAlign: 'center' }}
            ref={(ele) => (this.searchText = ele)}
            onChange={this.onSearch}
            placeholder="Αναζήτηση εφαρμογής βάση ID ή ονομασίας"
            value={this.state.searchText}
            onPressEnter={this.onSearch}
          />
        </Row>
        <TableWithLoader
          size={'small'}
          bordered={true}
          pagination={true}
          loader={{
            rows: 8,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={
            this.state.searchText
              ? clientStore.filteredClients
              : clientStore.clients
          }
          rowKey={(record) =>
            Object.keys(record).length === 0 ? v4() : record.id
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                this.setState({ selectedClientModal: record })
              },
            }
          }}
        />
      </>
    )
  }
}

export default observer(ClientPage)

import { Button, Col, Divider, Skeleton, Row, Input, Modal } from 'antd'
import { observer } from 'mobx-react'
import TableWithLoader from 'antd-table-loader'
import React, { Component } from 'react'
import organizationStore from '../../stores/organizationStore'
import { v4 } from 'uuid'
import authStore from '../../stores/authStore'
import { darkRed } from '../../colors'
import { Badge, Descriptions } from 'antd';

const { TextArea } = Input


class Organization extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: "",
      loading: false,
      modalVisible: false,
      selectedOrganization: null,
    }
  }

  componentDidMount() {
    organizationStore.getOrganizationRequests()
    organizationStore.getOrganizations()
    organizationStore.getRegionalUnits()
  }

  modalData = () => {
    const { selectedOrganization } = this.state;

    return [
      {
        key: '1',
        label: 'Tύπος Φορέα',
        children: organizationStore.getTitleById(selectedOrganization?.organization) || '',
      },
      {
        key: '2',
        label: 'Eπωνυμία Φορέα',
        children: selectedOrganization?.name || '',
      },
      {
        key: '3',
        label: 'ΑΦΜ Φορέα',
        children: selectedOrganization?.tax_number || '',
      },
      {
        key: '4',
        label: 'ΑΦΜ Εκπροσώπου Φορέα',
        children: selectedOrganization?.applicant_tax_number || '',
      },
      {
        key: '5',
        label: 'Περιφερειακή Ενότητα',
        children: organizationStore.getRegionalUnitByCode(selectedOrganization?.regional_unit) || '',
      },
      {
        key: '6',
        label: 'Ημερομηνία Αιτήματος',
        children: selectedOrganization?.requested_datetime.substring(0, 10) || '',
      },
      {
        key: '7',
        label: 'Τηλέφωνο',
        children: selectedOrganization?.phone_number || '',
      },
      {
        key: '8',
        span: 2,
        label: 'Email',
        children: selectedOrganization?.email || '',
      },
      {
        key: '9',
        label: 'Αξιολόγηση Αιτήματος',
        span: 3,
        children: (
          <Badge
            styles={
              {
                indicator: {
                  width: '8px',
                  height: '8px'
                }
              }
            }
            size={'default'}

            status={
              selectedOrganization?.is_accepted === true
                ? "success"
                : selectedOrganization?.is_accepted === false
                  ? "error"
                  : "default"
            }
            text={
              selectedOrganization?.is_accepted === true
                ? "Εγκρίθηκε"
                : selectedOrganization?.is_accepted === false
                  ? "Απορρίφθηκε"
                  : "Σε αναμονή"
            }
          />
        ),
      },
      {
        key: '10',
        label: 'Σχόλια Αιτήματος',
        children: (
          <>
            {selectedOrganization?.audit_remarks}
          </>
        ),
      },
    ];
  };

  reviewedModalData = () => {
    const { selectedOrganization } = this.state;

    return [
      {
        key: '1',
        label: 'Ελεγκτής',
        // children: organizationStore.getFullnameById(selectedOrganization?.organization) || '',
      },
      {
        key: '2',
        label: 'Hμερομηνία Ελέγχου',
        // children: selectedOrganization?.response_datetime.substring(0, 10) || '',
      },
      {
        key: '10',
        label: 'Σχόλια Eλεγκτή',
        children: (
          <>
            {selectedOrganization?.reviewer_remarks}
          </>
        ),
      },
    ];

  };

  onSearch = (e) => {
    const searchTerm = e.target.value
    this.setState({ searchText: searchTerm })
    organizationStore.onSearch(searchTerm)
  }

  columns = [
    {
      title: 'Τύπος Φορέα',
      align: 'center',
      render: (organization) => `${organizationStore.getTitleById(organization.organization)}`,
      key: 'name',
    },
    {
      title: 'Επωνυμία Φορέα',
      align: 'center',
      render: (organization) => `${organization.name}`,
      key: 'applicant_tax_number',
    },
    {
      title: 'ΑΦΜ Φορέα',
      align: 'center',
      render: (organization) => `${organization.tax_number}`,
      key: 'regional_unit',
    },
    {
      title: 'ΑΦΜ Εκπροσώπου Φορέα',
      align: 'center',
      render: (organization) => `${organization.applicant_tax_number}`,
      key: 'regional_unit',
    },
    {
      title: 'Περιφερειακή Ενότητα',
      align: 'center',
      render: (organization) => `${organizationStore.getRegionalUnitByCode(organization?.regional_unit)}`,
      key: 'regional_unit',
    },
    {
      title: 'Ημερομηνία Αιτήματος',
      align: 'center',
      render: (organization) => `${organization.requested_datetime.substring(0, 10)}`,
      key: 'regional_unit',
    },
    {
      title: 'Αξιολόγηση Αιτήματος',
      key: 'is_accepted',
      align: 'center',
      responsive: ['lg'],
      filters: [
        {
          text: 'Σε αναμονή',
          value: null,
        },
        {
          text: 'Εγκρίθηκε',
          value: true,
        },
        {
          text: 'Απορρίφθηκε',
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_accepted === value,
      render: (organization) => {
        let textColor
        let text
        if (organization.is_accepted === true) {
          textColor = 'green'
          text = 'Εγκρίθηκε'
        } else if (organization.is_accepted === false) {
          textColor = darkRed
          text = 'Απορρίφθηκε'
        } else {
          textColor = 'grey'
          text = 'Σε αναμονή'
        }

        return (
          <span
            style={{
              color: textColor,
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            {text}
          </span>
        )
      },
    },
  ]



  handleRowClick = (organization) => {
    this.setState({
      modalVisible: true,
      selectedOrganization: organization,
    })
  }

  handleButton = (isAccepted, organization) => {
    let req_data = {
      id: organization.id,
      is_accepted: isAccepted,
      reviewer: authStore.userId,
      reviewer_remarks: this.state.form.audit_remakrs,
    }

    organizationStore.updateOrganizationRequests(req_data)

    this.setState({
      modalVisible: false,
      selectedOrganization: null,
    })
  }

  handleModalClose = () => {
    this.setState({
      modalVisible: false,
      selectedOrganization: null,
    })
  }

  render() {
    const { modalVisible, selectedOrganization } = this.state

    return (
      <>
        <Divider nothing={organizationStore.regionalUnits} nothing2={organizationStore.organizations}>Πορεία Αιτημάτων Φορέων</Divider>
        <Row
          align={'center'}
          style={{ marginTop: '2%', marginBottom: '2%' }}
        ><Input.Search
            style={{ width: "40%", textAlign: "center" }}
            ref={(ele) => (this.searchText = ele)}
            onChange={this.onSearch}
            placeholder="Αναζήτηση με βάση το Α.Φ.Μ. Φορέα ή την Επωνυμία Φορέα."
            value={this.state.searchText}
            onPressEnter={this.onSearch}
          /></Row>
        <TableWithLoader
          size={'small'}
          bordered={true}
          pagination={true}
          loader={{
            rows: 8,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={
            this.state.searchText
              ? organizationStore.filteredRequests
              : organizationStore.organizationRequests
          }
          rowKey={(organization) =>
            Object.keys(organization).length === 0 ? v4() : organization.id
          }
          onRow={(organization) => {
            return {
              onClick: () => {
                this.handleRowClick(organization)
              },
              style: { cursor: 'pointer' },
            }
          }}
        />
        {selectedOrganization && (
          <Modal
            open={modalVisible}
            title="Πληροφορίες Αιτήματος Φορέα"
            onCancel={this.handleModalClose}
            width={800}
            footer={
              selectedOrganization.is_accepted === null && (
                <>
                  <Button
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                    }}
                    key="approve"
                    onClick={() =>
                      this.handleButton(true, selectedOrganization)
                    }
                  >
                    Έγκριση
                  </Button>
                  <Button
                    style={{
                      backgroundColor: darkRed,
                      color: 'white',
                    }}
                    key="reject"
                    onClick={() =>
                      this.handleButton(false, selectedOrganization)
                    }
                  >
                    Απόρριψη
                  </Button>
                  <Button
                    style={{
                      marginLeft: '10px',
                    }}
                    key="close"
                    onClick={this.handleModalClose}
                  >
                    Κλείσιμο
                  </Button>
                </>
              )
            }
          >
            <Divider />
            <Descriptions
              layout="vertical"
              bordered
              items={this.modalData()} />

            {selectedOrganization.is_accepted !== null && (
              <>
                <Divider>Πληροφορίες Ελεγκτή</Divider>

                <Descriptions
                  layout="vertical"
                  bordered
                  items={this.reviewedModalData()}
                />
              </>
            )}

            {selectedOrganization.is_accepted === null && (
              <>
                <Divider>Eπεξεργασία Αίτησης</Divider>
                <p>Σχόλιο Ελεγκτή</p>
                <TextArea
                  rows={3}
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        audit_remakrs: e.target.value,
                      },
                    })
                  }}
                  placeholder=""
                  disabled={false}
                />
              </>
            )}
          </Modal>
        )}
      </>
    )
  }
}

export default observer(Organization)

import { Button, Col, Row } from "antd";
import React from "react";
import reportStore from "../../stores/reportStore";
import { DownloadOutlined } from "@ant-design/icons";


class SessionPage extends React.Component {

    render() {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => reportStore.downloadSessionsToXlsx()}>
                            Αποθήκευση συνεδριάσεων σε xlsx
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}

export default SessionPage
import React from "react"
import { Button, Card, Col, DatePicker, Row } from "antd"
import { DATETIME_FORMAT } from "../../config"
import reportStore from "../../stores/reportStore"
import { DownloadOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { observer } from "mobx-react"

class SessionPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDatetime: null,
            toDatetime: null
        }
    }

    onRangeChange = (dates) => {
        const fromDatetime = dates[0];
        const toDatetime = dates[1];

        this.setState({
            fromDatetime: fromDatetime,
            toDatetime: toDatetime,
        })
    }

    shouldDayBeDisabled = (date) => {
        return !dayjs().isAfter(date)
    }

    onDownloadPressed = () => {
        reportStore.setLoading(true)
        reportStore.downloadSessionsToXlsx(this.state.fromDatetime, this.state.toDatetime)
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={12}>
                        <Card title="Εξαγωγή συνεδριάσεων">
                            <Row align={'center'} gutter={[20, 20]} style={{ width: '100%' }}>
                                <Col span={24}>
                                    <p>Επιλέξτε το χρονικό διαστήμα:</p>
                                    <DatePicker.RangePicker
                                        placeholder={['Από', 'Εώς']}
                                        style={{ width: '90%' }}
                                        monthCellRender={(date, today) => date.format("MMMM")}
                                        allowClear={false}
                                        autoFocus={true}
                                        bordered={true}
                                        showTime={true}
                                        placement={'top'}
                                        disabledDate={this.shouldDayBeDisabled}
                                        format={DATETIME_FORMAT}
                                        onChange={this.onRangeChange}
                                        showTime={{
                                            minuteStep: 30,
                                        }}
                                        defaultValue={[
                                            this.state.fromDatetime,
                                            this.state.toDatetime,
                                        ]}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Button
                                        loading={reportStore.loading}
                                        style={{ width: '90%' }}
                                        type="primary"
                                        disabled={!this.state.fromDatetime && !this.state.toDatetime}
                                        icon={<DownloadOutlined />}
                                        onClick={this.onDownloadPressed}>
                                        Αποθήκευση συνεδριάσεων σε xlsx
                                    </Button>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>

            </>
        )
    }
}

export default observer(SessionPage)
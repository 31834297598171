import React from "react"
import { Card, Col, Row } from "antd"
import { FileTextOutlined } from "@ant-design/icons"
import { openInNewTab } from "../../utils/generic"
import { primaryColor } from "../../colors"

class Manual extends React.PureComponent {
    render() {
        return (
            <Card onClick={() => openInNewTab(this.props.manual.url)} style={{ cursor: 'pointer', borderColor: primaryColor }}>
                <Row align={'center'} gutter={[20, 20]} style={{ width: '100%' }}>
                    <Col span={10}>
                        <FileTextOutlined style={{ fontSize: '24px' }} />
                    </Col>
                    <Col span={14} style={{ textDecoration: 'underline' }}>
                        {this.props.manual.manual_title}
                    </Col>
                </Row>
            </Card>
        )
    }
}

export default Manual
import React, { PureComponent } from 'react'
import { primaryColor } from '../../colors'
import { CopyOutlined } from "@ant-design/icons"
import { message } from 'antd'

class JsonSnippet extends PureComponent {

    copyToClipboard = () => {
        const formattedJson = JSON.stringify(this.props.json, null, 2)

        navigator.clipboard.writeText(
            formattedJson
        ).then(() => {
            message.info("Αντιγράφηκε")
        }).catch(err => {
            console.error('Could not copy text: ', err)
        })
    }

    render() {
        const formattedJson = JSON.stringify(this.props.json, null, 4)

        return (
            <div style={styles.containerStyle}>
                <button style={styles.buttonStyle} onClick={this.copyToClipboard}>
                    <CopyOutlined />
                </button>
                <pre>{formattedJson}</pre>
            </div>
        )
    }
}

const styles = {
    containerStyle: {
        border: '3px solid ' + primaryColor,
        borderRadius: '5px',
        padding: '4px',
        margin: '8px',
        backgroundColor: '#f0f0f0',
        maxHeight: '25vh',
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
    },
    buttonStyle: {
        position: 'absolute',
        top: '15%',
        right: '1px',
        backgroundColor: primaryColor,
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '5px 10px',
        cursor: 'pointer',
        fontSize: '16px',
    }
}

export default JsonSnippet
import { observable, action, makeObservable } from 'mobx'
import { api } from '../api/api'
import { resetStoreOnClientChange } from '../utils/storeActions'
import { hasBadCharachters } from '../utils/generic'

class ClientStore {
  filteredClients = []
  clients = null
  clientsOptions = []

  selectedClientId = null

  clientFormState = {}

  onSearch = (s) => {
    const text = s

    if (hasBadCharachters(text)) {
      return
    }

    const reg = new RegExp(text, 'gi')

    const filteredData = this.clients
      .map((client) => {
        const idMatch = client.clientId.match(reg)
        const nameMatch = client.name.match(reg)

        if (!idMatch && !nameMatch) {
          return null
        }

        return client
      })
      .filter((record) => !!record)

    this.filteredClients = filteredData
  }

  cleanClientFormState = () => {
    this.clientFormState = {}
  }

  convertToOptions = (clients) => {
    const clientsOptions = []
    clients.forEach((client) => {
      clientsOptions.push({
        value: client.clientId,
        name: client.description,
      })
    })

    this.clientsOptions = clientsOptions
  }

  constructor() {
    makeObservable(this, {
      clients: observable,
      clientsOptions: observable,

      getClients: action,
    })
  }

  setClient = (clientId) => {
    this.selectedClientId = clientId
    localStorage.setItem('clientId', clientId)

    resetStoreOnClientChange()
  }

  getClient = () => {
    if (this.selectedClientId) {
      return this.selectedClientId
    }

    const storageClientId = localStorage.getItem('clientId')
    if (storageClientId) {
      this.setClient(storageClientId)
      return storageClientId
    }

    if (this.clients?.length > 0) {
      const defaultClientId = this.clients[0].clientId
      this.setClient(defaultClientId)
      return defaultClientId
    }

    return null
  }

  getClients = () => {
    api
      .get('clients')
      .then((response) => {
        this.clients = response.data
        this.convertToOptions(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  massiveSetupByFile = (file, clientId, onSuccess, onError, onProgress) => {
    const formData = new FormData()
    formData.append('file', file)

    api
      .post(`${clientId}/setup`, formData, {
        onUploadProgress: (progressEvent) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onProgress({ percent })
        },
      })
      .then((response) => {
        onSuccess()
        this.clientFormState = {
          success: true,
          loading: false,
        }
        notification.success({
          message: 'Επιτυχία',
          description: 'Η παραμετροποίηση της εφαρμογής τελείωσε επιτυχώς',
        })
        resetStoreOnClientChange()
      })
      .catch((error) => {
        onError(error)
        this.clientFormState = {
          success: false,
          loading: false,
        }
        notification.error({
          message: 'Αποτυχία',
          description: 'Κάτι πήγε λάθος',
        })
      })
  }
}

const clientStore = new ClientStore()
export default clientStore

import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"
import { notification } from "antd"
import { covnertJsonToXlsxtAndDownloadTheFile } from "../utils/xlsx"

class ReportStore {
    sessions = []

    constructor() {
        makeObservable(this, {
            sessions: observable,

            getSessions: action,
            downloadSessionsToXlsx: action
        })
    }

    getSessions = () => {
        api
            .get("sessions")
            .then((response) => {
                this.sessions = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    downloadSessionsToXlsx = () => {
        api
            .get("sessions")
            .then((response) => {
                this.sessions = response.data
                covnertJsonToXlsxtAndDownloadTheFile(
                    this.sessions,
                    "sessions.xlsx",
                    "Sessions 1"
                )
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

const reportStore = new ReportStore()
export default reportStore

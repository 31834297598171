const isDevelopment = process.env.NODE_ENV === "development"

export const API_URL = isDevelopment
  ? "https://api.sdx.iacs-2024.lab.synelixis.com/api/v1"
  : "process.env.REACT_APP_API_URL"

export const DEFAULT_REALM = isDevelopment
  ? "sdx-dev-2024"
  : "process.env.REACT_APP_DEFAULT_REALM"

export const DATE_FORMAT = "DD/MM/YYYY"
export const DATETIME_FORMAT = DATE_FORMAT + " HH:mm"

export const PLATFORM_NAME = "Σύστημα Διαχείρισης Χρηστών"

export const KEYCLOAK_CONFIG = {
  url: isDevelopment
    ? "https://auth-25.iacs-2024.lab.synelixis.com"
    : "process.env.REACT_APP_KEYCLOAK_URL",
  realm: isDevelopment ? "sdx-dev-2024" : "process.env.REACT_APP_KEYCLOAK_REALM",
  clientId: isDevelopment
    ? "opekepe-sdx-ui"
    : "process.env.REACT_APP_KEYCLOAK_CLIENT_ID",
}

/*
 * **Below import the images that you want to use**
 *
 *
 * import myImageName from "../public/my_image.png"
 * export const MY_IMAGE_NAME = myImageName
 *
 *
 * Writing the above code makes webpack to keep the images in the build folder after the production build
 * If I try to import images dynamicly in runtime.
 * They will not be available, due to webpack cleaning unused files when it creates the bundle
 */
import GovLogo from "../public/gov_gr_logo.png"
export const GOV_GR_LOGO = GovLogo

import FundingImage from "../public/all_funding_bodies.png"
export const FUNDING_IMAGE = FundingImage

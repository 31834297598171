import { observable, action, makeObservable } from "mobx"
import { api, apiClient } from "../api/api"
import { notification } from "antd"
import { hasBadCharachters } from "../utils/generic"
import meStore from "./meStore"

class PermissionStore {
    searchTerm = ""
    filteredPermissions = []
    permissions = null
    usersScopes = []
    permissionsOptions = []
    permissionFormState = {}

    individualUserPermissions = null

    onSearch = (s) => {
        this.searchTerm = s
        const text = s

        if (hasBadCharachters(text)) {
            return
        }

        const reg = new RegExp(text, "gi")

        const filteredData = this.permissions
            .map((permission) => {
                const nameMatch = permission.name?.match(reg)
                const descriptionMatch = permission.description?.match(reg)

                if (!nameMatch && !descriptionMatch) {
                    return null
                }

                return permission
            })
            .filter((record) => !!record)


        this.filteredPermissions = filteredData
    }

    cleanPermissionFormState = () => {
        this.permissionFormState = {}
    }

    resetStore = () => {
        this.permissions = null
        this.permissionsOptions = []
        this.filteredPermissions = []
        this.permissionFormState = {}
        this.searchTerm = ""
        this.individualUserPermissions = null

        this.getPermissions()
    }

    convertToOptions = (permissions) => {
        const permissionsOptions = []
        permissions.forEach((permission) => {
            permissionsOptions.push({
                value: permission.name,
                name: permission.name
            })
        })

        this.permissionsOptions = permissionsOptions
    }

    constructor() {
        makeObservable(this, {
            permissions: observable,
            permissionFormState: observable.deep,
            permissionsOptions: observable,
            searchTerm: observable,
            usersScopes: observable,
            individualUserPermissions: observable,

            getPermissions: action,
            createPermission: action,
            createPermissionByFile: action,

            createUserScopes: action,
            getUsersScopes: action,
            getIndividualUserPermissions: action,
            cleanIndividualUserPermissions: action,

            resetStore: action
        })
    }

    setFormState = state => {
        this.permissionFormState = state
    }

    createUserScopes = (users, scopes) => {
        apiClient()
            .post("users/permissions", {
                user_ids: users, scopes: scopes,
                organization_instance_id: meStore.getSelectedOrganizationInstanceId()
            })
            .then((response) => {
                this.permissionFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Ο περιορισμός αποθηκέυτηκε",
                })
            })
            .catch((error) => {
                this.setFormState({
                    success: true,
                    loading: false,
                })
                notification.error({
                    message: "Αποτυχία",
                    description: "Υπήρχε πρόβλημα κατά τη διαρκεία ανάθεσης περιορισμού",
                })
                console.log(error)
            })

    }

    getUsersScopes = () => {
        apiClient()
            .get("users/permissions")
            .then((response) => {
                this.usersScopes = response.data.scopes.map(obj => {
                    return {
                        id: obj, name: obj
                    }
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getIndividualUserPermissions = (userId) => {
        apiClient()
            .get(`users/${userId}/organization_instance/${meStore.getSelectedOrganizationInstanceId()}/permissions`)
            .then((response) => {
                this.individualUserPermissions = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    cleanIndividualUserPermissions = () => {
        this.individualUserPermissions = null
    }

    getPermissions = () => {
        apiClient()
            .get("permissions")
            .then((response) => {
                this.permissions = response.data.map(obj => {
                    return {
                        ...obj,
                        id: obj.name
                    }
                })
                this.convertToOptions(this.permissions)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    createPermission = (permission) => {
        apiClient()
            .post("permissions", permission)
            .then((response) => {
                this.permissionFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Ο περιορισμός αποθηκέυτηκε",
                })
                this.getPermissions()
            })
            .catch((error) => {
                console.log(error)
                this.resourceFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }

    createPermissionByFile = (file, onSuccess, onError, onProgress) => {
        const formData = new FormData()
        formData.append("file", file)

        apiClient()
            .post("permissions/file",
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        onProgress({ percent })
                    },
                }
            )
            .then((response) => {
                onSuccess()
                this.permissionFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Οι περιορισμοί αποθηκεύτηκαν",
                })
                this.getPermissions()
            })
            .catch((error) => {
                onError(error)
                this.permissionFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }
}

const permissionStore = new PermissionStore()
export default permissionStore

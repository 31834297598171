import { Button, Col, Divider, Form, Input, Row, Space, Upload } from "antd"
import React from "react"
import permissionStore from "../../stores/permissionStore"
import { observer } from "mobx-react"
import JsonSnippet from "../../components/json/JsonSnippet"
import { examplePermissions } from "../../components/json/snippets"

class CreatePermissionPage extends React.Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            form: {},
            loading: false,
        }
    }

    onSubmitPressed = () => {
        this.setState({ loading: true })
        permissionStore.createPermission(this.state.form)
    }

    componentDidUpdate() {
        if (permissionStore.permissionFormState?.success) {
            this.setState({
                form: {},
                loading: permissionStore.permissionFormState?.loading,
            })
            permissionStore.cleanPermissionFormState()
            this.formRef.current.resetFields()
        }

        if (permissionStore.permissionFormState?.success == false) {
            this.setState({
                loading: permissionStore.permissionFormState?.loading,
            })
            permissionStore.cleanPermissionFormState()
        }
    }

    renderForm = () => {
        return (
            <>
                <Form ref={this.formRef} layout="vertical">
                    <Row gutter={[20, 20]}>
                        <Col span={12}>
                            <Form.Item
                                name={"name"}
                                label={"Ονομασία περιορισμού"}
                                rules={[
                                    {
                                        message: "Παρακαλώ συμπληρώστε την ονομασία του περιορισμού",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                name: e.target.value,
                                            },
                                        })
                                    }}
                                    placeholder={""}
                                    disabled={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name={"description"}
                                label={"Περιγραφή περιορισμού"}
                                rules={[
                                    {
                                        message: "Παρακαλώ συμπληρώστε την περιγραφή του περιορισμού",
                                        required: true,
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                description: e.target.value,
                                            },
                                        })
                                    }}
                                    placeholder={""}
                                    disabled={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        )
    }

    customRequestImplementation = async ({
        file,
        onSuccess,
        onError,
        onProgress,
    }) => {
        permissionStore.createPermissionByFile(
            file, onSuccess, onError, onProgress
        )
    }


    render() {
        return (
            <>
                <Divider>Δημιουργία περιορισμού</Divider>

                {this.renderForm()}

                <div style={{ display: "flex", gap: "16px" }}>
                    <Button key="ok" type="primary"
                        nothing={permissionStore.permissionFormState?.loading ? true : false}
                        loading={this.state.loading} style={{ width: "100%" }} onClick={() => this.onSubmitPressed()}>
                        Δημιουργία περιορισμού
                    </Button>
                    <Button
                        loading={this.state.loading}
                        style={{ width: "100%" }}
                        onClick={() => permissionStore.permissionFormState = {
                            success: true,
                            loading: false,
                        }}
                    >
                        Kαθαρισμός Φόρμας
                    </Button>
                </div>
                <Row style={{ marginTop: "24px", width: '100%' }}>

                    <Divider>Δημιουργία περιορισμού μέσω αρχείου</Divider>

                    <Col span={12}>
                        <Upload style={{ width: "100%" }}

                            {...{
                                showUploadList: {
                                    showDownloadIcon: false,
                                    showRemoveIcon: false,
                                    previewIcon: false,
                                },
                                customRequest: this.customRequestImplementation,
                                onChange: (info) => {
                                    console.log(info)
                                },
                            }}
                        >
                            <Button key="ok" type="primary" style={{ width: "100%" }}>
                                Μεταφορτώστε αρχείο JSON
                            </Button>
                        </Upload>
                    </Col>
                    <Col span={12}>
                        <Divider>Υπόδειγμα αρχείου JSON</Divider>
                        <JsonSnippet json={examplePermissions} />
                    </Col>
                </Row>
            </>
        )
    }
}

export default observer(CreatePermissionPage)

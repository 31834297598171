import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"
import { notification } from "antd"
import { hasBadCharachters } from "../utils/generic"

class RoleStore {
    filteredRoles = []
    roles = null
    roleFormState = {}

    onSearch = (s) => {
        const text = s

        if (hasBadCharachters(text)) {
            return
        }

        const reg = new RegExp(text, "gi")

        const filteredData = this.roles
            .map((role) => {
                const nameMatch = role.name?.match(reg)

                if (!nameMatch) {
                    return null
                }

                return role
            })
            .filter((record) => !!record)


        this.filteredRoles = filteredData
    }

    cleanRoleFormState = () => {
        this.roleFormState = {}
    }

    constructor() {
        makeObservable(this, {
            roles: observable,
            roleFormState: observable.deep,

            getRoles: action,
            createRole: action,
            createRoleByFile: action
        })
    }

    getRoles = () => {
        api
            .get("roles")
            .then((response) => {
                this.roles = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    createRole = (role) => {
        api
            .post("roles", role)
            .then((response) => {
                this.roleFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Ο ρόλος αποθηκέυτηκε",
                })
                this.getRoles()
            })
            .catch((error) => {
                this.roleFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }

    createRoleByFile = (file, onSuccess, onError, onProgress) => {
        const formData = new FormData()
        formData.append("file", file)

        api
            .post("roles/file",
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        onProgress({ percent })
                    },
                }
            )
            .then((response) => {
                onSuccess()
                this.roleFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Οι ρόλοι αποθηκεύτηκαν",
                })
                this.getRoles()
            })
            .catch((error) => {
                onError(error)
                this.roleFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }
}

const roleStore = new RoleStore()
export default roleStore

import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"

class ManualStore {
    manuals = []

    constructor() {
        makeObservable(this, {
            manuals: observable,

            getManuals: action,
        })
    }

    getManuals = () => {
        api
            .get("manuals")
            .then((response) => {
                this.manuals = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

const manualStore = new ManualStore()
export default manualStore

import React from "react"


class PageTemplate extends React.Component {
    render() {
        return (
            <p>
                Σύστημα Διαχείρισης Χρηστών
            </p>
        )
    }
}

export default PageTemplate
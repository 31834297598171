
export const UserRole = {
    /**
     * Please respect the order
     */
    SDX_ADMIN: "sdx_admin",
    ORGANIZATION_ADMIN: "_admin",
    ORGANIZATION_EMPLOYEE: "_employee",
    NO_ROLE: "no_role"
}

export const UserRoleText = {
    [UserRole.SDX_ADMIN]: "Διαχειριστής ΣΔΧ",
    [UserRole.ORGANIZATION_ADMIN]: "Διαχειριστής Φορέα",
    [UserRole.ORGANIZATION_EMPLOYEE]: "Υπάλληλος Φορέα",
    [UserRole.NO_ROLE]: "-"
}
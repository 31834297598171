import React from "react"
import TableWithLoader from "antd-table-loader"
import { Divider, Input, Row, Skeleton, Tag } from "antd"
import { observer } from "mobx-react"
import { v4 } from "uuid"
import roleStore from "../../stores/roleStore"

class RolePage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: ""
        }
    }

    componentDidMount() {
        roleStore.getRoles()
    }

    columns = [
        {
            title: "Ονομασία",
            align: "center",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Περιγραφή",
            align: "left",
            dataIndex: "description",
            key: "description",
        }
    ]

    onSearch = (e) => {
        const searchTerm = e.target.value
        this.setState({ searchText: searchTerm })
        roleStore.onSearch(searchTerm)
    }

    render() {
        return (
            <>
                <Divider>Ρόλοι</Divider>
                <Row align={"center"} style={{ marginTop: "2%", marginBottom: "2%" }}>
                    <Input.Search
                        style={{ width: "40%", textAlign: "center" }}
                        ref={(ele) => (this.searchText = ele)}
                        onChange={this.onSearch}
                        placeholder="Αναζήτηση ρόλου βάση ονομασίας"
                        value={this.state.searchText}
                        onPressEnter={this.onSearch}
                    />
                </Row>
                <TableWithLoader
                    size={"small"}
                    bordered={true}
                    pagination={true}
                    loader={{
                        rows: 8,
                        component: <Skeleton active loading title={false} />,
                    }}
                    columns={this.columns}
                    dataSource={
                        this.state.searchText
                            ? roleStore.filteredRoles
                            : roleStore.roles
                    }
                    rowKey={(record) =>
                        Object.keys(record).length === 0 ? v4() : record.id
                    }
                />
            </>
        )
    }
}

export default observer(RolePage)

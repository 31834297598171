import React from "react"
import TableWithLoader from "antd-table-loader"
import { Divider, Input, Row, Skeleton, Tag } from "antd"
import { observer } from "mobx-react"
import { v4 } from "uuid"
import resourceStore from "../../stores/resourceStore"

class ResourcePage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            searchText: ""
        }
    }

    componentDidMount() {
        resourceStore.getResources()
    }

    columns = [
        {
            title: "Μέθοδος",
            align: "center",
            dataIndex: "method",
            key: "type",
        },
        {
            title: "Ονομασία",
            align: "center",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "URL",
            align: "left",
            render: (record) => record.endpoint,
            key: "url",
        },
        {
            title: "Περιορισμοί",
            align: "left",
            render: (record) => record.requiredPermissions.map(permission => <Tag>{permission}</Tag>),
            key: "requiredPermissions",
        },
    ]

    onSearch = (e) => {
        const searchTerm = e.target.value
        resourceStore.onSearch(searchTerm)
    }

    render() {
        return (
            <>
                <Divider>Πόροι</Divider>
                <Row align={"center"} style={{ marginTop: "2%", marginBottom: "2%" }}>
                    <Input.Search
                        style={{ width: "40%", textAlign: "center" }}
                        ref={(ele) => (this.searchText = ele)}
                        onChange={this.onSearch}
                        placeholder="Αναζήτηση πόρου βάση URL ή ονομασίας ή περιορισμού"
                        value={resourceStore.searchTerm}
                        onPressEnter={this.onSearch}
                    />
                </Row>
                <TableWithLoader
                    size={"small"}
                    bordered={true}
                    pagination={true}
                    loader={{
                        rows: 8,
                        component: <Skeleton active loading title={false} />,
                    }}
                    columns={this.columns}
                    dataSource={
                        resourceStore.searchTerm
                            ? resourceStore.filteredResources
                            : resourceStore.resources
                    }
                    rowKey={(record) =>
                        Object.keys(record).length === 0 ? v4() : record.id
                    }
                />
            </>
        )
    }
}

export default observer(ResourcePage)

import React from "react"
import { primaryColor } from "./colors"
import { ConfigProvider } from "antd"
import el_GR from "antd/locale/el_GR"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import PrivateComponent from "./components/PrivateComponent"
import PageTemplate from "./screens/misc/PageTemplate"
import NotFound from "./screens/misc/NotFound"
import Loader from "./components/Loader"
import UsersPage from "./screens/users/UsersPage"
import PermissionPage from "./screens/permissions/PermissionPage"
import CreatePermissionPage from "./screens/permissions/CreatePermissionPage"
import ClientPage from "./screens/ClientPage"
import ResourcePage from "./screens/resources/ResourcePage"
import CreateResourcePage from "./screens/resources/CreateResourcePage"
import CreateUserPage from "./screens/users/CreateUserPage"
import { initializeKeycloak } from "./utils/keycloak"
import RolePage from "./screens/roles/RolePage"
import CreateRolePage from "./screens/roles/CreateRolePage"
import CreateOrgPage from "./screens/organizations/CreateOrgPage"
import ApplicationProgress from "./screens/organizations/ApplicationProgressPage"
import { UserRole } from "./utils/userRole"
import authStore from "./stores/authStore"
import { observer } from "mobx-react"
import UserPemrissionPage from "./screens/organizations/UserPermissionPage"
import SessionPage from "./screens/reports/SessionPage"
import OrganizationUsersPage from "./screens/organizations/OrganizationUsersPage"

const sdxAdminRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "/users",
    element: (
      <PrivateComponent>
        <UsersPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-user",
    element: (
      <PrivateComponent>
        <CreateUserPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/roles",
    element: (
      <PrivateComponent>
        <RolePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-role",
    element: (
      <PrivateComponent>
        <CreateRolePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/application-progress",
    element: (
      <PrivateComponent>
        <ApplicationProgress />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-user-management",
    element: (
      <PrivateComponent>
        <UserPemrissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-users",
    element: (
      <PrivateComponent>
        <OrganizationUsersPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/permissions",
    element: (
      <PrivateComponent>
        <PermissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-permission",
    element: (
      <PrivateComponent>
        <CreatePermissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/resources",
    element: (
      <PrivateComponent>
        <ResourcePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-resource",
    element: (
      <PrivateComponent>
        <CreateResourcePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/clients",
    element: (
      <PrivateComponent>
        <ClientPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/references",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "/sessions",
    element: (
      <PrivateComponent>
        <SessionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

const allRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "/users",
    element: (
      <PrivateComponent>
        <UsersPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-user",
    element: (
      <PrivateComponent>
        <CreateUserPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/roles",
    element: (
      <PrivateComponent>
        <RolePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-role",
    element: (
      <PrivateComponent>
        <CreateRolePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/application-progress",
    element: (
      <PrivateComponent>
        <ApplicationProgress />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-apply",
    element: (
      <PrivateComponent>
        <CreateOrgPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-user-management",
    element: (
      <PrivateComponent>
        <UserPemrissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-users",
    element: (
      <PrivateComponent>
        <OrganizationUsersPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/permissions",
    element: (
      <PrivateComponent>
        <PermissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-permission",
    element: (
      <PrivateComponent>
        <CreatePermissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/resources",
    element: (
      <PrivateComponent>
        <ResourcePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/create-resource",
    element: (
      <PrivateComponent>
        <CreateResourcePage />
      </PrivateComponent>
    ),
  },
  {
    path: "/clients",
    element: (
      <PrivateComponent>
        <ClientPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/references",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

const organizationAdminRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "/application-progress",
    element: (
      <PrivateComponent>
        <ApplicationProgress />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-apply",
    element: (
      <PrivateComponent>
        <CreateOrgPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-user-management",
    element: (
      <PrivateComponent>
        <UserPemrissionPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-users",
    element: (
      <PrivateComponent>
        <OrganizationUsersPage />
      </PrivateComponent>
    ),
  },
  {
    path: "/references",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

const noRoleRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "/application-progress",
    element: (
      <PrivateComponent>
        <ApplicationProgress />
      </PrivateComponent>
    ),
  },
  {
    path: "/organization-apply",
    element: (
      <PrivateComponent>
        <CreateOrgPage />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

const organizationEmployeeRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <PageTemplate />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
])

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    initializeKeycloak()
  }

  render() {
    if (authStore.role == null) {
      return <Loader absoluteCenter={true} />
    }

    return (
      <>
        <ConfigProvider
          locale={el_GR}
          theme={{
            token: {
              colorPrimary: primaryColor,
              fontSize: 13,
              lineWidth: 1.4,
              colorText: "black",
              colorInfo: primaryColor,
              lineWidth: 1.2,
            },
            components: {
              Input: {
                colorPrimary: primaryColor,
              },
              Radio: {
                colorPrimary: primaryColor,
              },
              Button: {
                colorPrimary: primaryColor,
              },
              Card: {
                colorPrimary: primaryColor,
              },
              Tabs: {
                colorPrimary: primaryColor,
              },
              Modal: {
                colorPrimary: primaryColor,
              },
              Switch: {
                colorPrimary: primaryColor,
              },
              Table: {
                colorPrimary: primaryColor,
              },
              Menu: {
                colorBgLayout: primaryColor,
                darkItemBg: primaryColor,
              },
            },
          }}
        >
          <div
            style={{
              // minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {this.renderPrivateNavigation()}
          </div>
        </ConfigProvider>
      </>
    )
  }

  renderPrivateNavigation = () => {
    const role = authStore.role

    if (role == UserRole.SDX_ADMIN) {
      return <RouterProvider router={sdxAdminRouter} />
    }

    if (role.endsWith(UserRole.ORGANIZATION_ADMIN)) {
      return <RouterProvider router={organizationAdminRouter} />
    }

    if (role.endsWith(UserRole.ORGANIZATION_EMPLOYEE)) {
      return <RouterProvider router={organizationEmployeeRouter} />
    }

    if (role == UserRole.NO_ROLE){
      return <RouterProvider router={noRoleRouter} />
    }

    return <RouterProvider router={allRouter} />
  }
}

export default observer(Root)

import { observable, action, makeObservable } from 'mobx'
import { onLogoutWithKeycloak } from '../utils/keycloak'
import { UserRole, UserRoleText } from '../utils/userRole'

class AuthStore {
  userId = ''
  firstName = ''
  lastName = ''
  token = ''
  username = ''
  email = ''
  loginTimestamp = null
  realmRoles = []
  role = null

  constructor() {
    makeObservable(this, {
      userId: observable,
      firstName: observable,
      lastName: observable,
      token: observable,
      username: observable,
      email: observable,
      loginTimestamp: observable,
      role: observable,
      forceLogout: action,
      onUserlogout: action,
      setUser: action,
      setRealmRoles: action
    })
  }

  forceLogout = () => {
    // Keycloak logout
    this.userId = ''
    this.firstName = ''
    this.lastName = ''
    this.token = ''
    this.username = ''
    this.email = ''
    this.loginTimestamp = null
    localStorage.removeItem("kc_token")
    onLogoutWithKeycloak()
  }

  onUserlogout = () => {
    // Keycloak logout
    this.userId = ''
    this.firstName = ''
    this.lastName = ''
    this.token = ''
    this.username = ''
    this.email = ''
    this.loginTimestamp = null
    localStorage.removeItem("kc_token")
    onLogoutWithKeycloak()
  }

  setUser = (profile) => {
    this.userId = profile.id
    this.firstName = profile.firstName
    this.lastName = profile.lastName
    this.token = profile.token
    this.username = profile.username
    this.email = profile.email
    this.loginTimestamp = new Date()
  }

  setRealmRoles = (realmRoles) => {
    this.realmRoles = realmRoles.filter(role =>
      Object.values(UserRole).some(userRole => role.includes(userRole))
    )

    for (const roleKey of Object.keys(UserRole)) {
      const rolePattern = UserRole[roleKey]
      this.role = realmRoles.find(role => role.includes(rolePattern))
      if (this.role) {
        break
      }
    }

    if (!this.role) {
      this.role = UserRole.NO_ROLE
    }
  }

  getRoleText = () => {
    const role = this.role

    if (UserRoleText[role]) {
      return UserRoleText[role];
    }

    if (role.endsWith('_admin')) {
      return UserRoleText[UserRole.ORGANIZATION_ADMIN];
    }

    if (role.endsWith('_employee')) {
      return UserRoleText[UserRole.ORGANIZATION_EMPLOYEE];
    }

    // Default to NO_ROLE if no match
    return UserRoleText[UserRole.NO_ROLE];
  }
}

const authStore = new AuthStore()
export default authStore

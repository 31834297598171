import { Button, Col, Divider, Row, Skeleton, Table } from "antd";
import React from "react";
import TableWithLoader from "antd-table-loader"
import userStore from "../../stores/userStore";
import { v4 } from "uuid";
import { observer } from "mobx-react";
import permissionStore from "../../stores/permissionStore";
import meStore from "../../stores/meStore";

class UserPemrissionPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            selectedUsers: [],
            selectedScopes: []
        }
    }

    componentDidMount() {
        meStore.getManagedOrganizationUsers()
        permissionStore.getUsersScopes()
    }

    componentDidUpdate() {
        if (permissionStore.permissionFormState?.success) {
            this.setState({
                selectedUsers: [],
                selectedScopes: [],
                loading: false,
            })
            permissionStore.cleanPermissionFormState()
        }

        if (permissionStore.permissionFormState?.success == false) {
            this.setState({
                loading: false,
            })
            permissionStore.cleanPermissionFormState()
        }
    }

    columns = [
        {
            title: "Α.Φ.Μ.",
            align: "center",
            render: (record) => `${record.attributes.tax_number[0]}`,
            key: "tax_number",
        },
        {
            title: "Ονοματεπώνυμο",
            align: "center",
            render: (record) =>
                `${record?.firstName ? record?.firstName : "Δ.Υ."} ${record?.lastName ? record?.lastName : "Δ.Υ."}`,
            key: "name",
        },
    ]

    usersScopeColumns = [
        {
            title: "Ονομασία περιορισμού",
            align: "center",
            render: (record) => record.name,
            key: "name",
        },
    ]

    saveUserScopes = () => {
        this.setState({ loading: true })
        permissionStore.createUserScopes(
            this.state.selectedUsers,
            this.state.selectedScopes
        )
    }

    render() {
        return (
            <>
                <Divider>
                    Διαχείριση χρηστών
                </Divider>
                <Row gutter={[20, 20]}>
                    <Col span={8}>
                        <TableWithLoader
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.selectedUsers,
                                onChange: (selectedRowKeys, selectedRows) => this.setState({ selectedUsers: selectedRowKeys })

                            }}
                            size={"small"}
                            bordered={true}
                            pagination={true}
                            loader={{
                                rows: 8,
                                component: <Skeleton active loading title={false} />,
                            }}
                            columns={this.columns}
                            dataSource={meStore.managedOrganizationUsers}
                            rowKey={(record) => record.id
                            }
                        />

                    </Col >
                    <Col span={16}>
                        <TableWithLoader
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys: this.state.selectedScopes,
                                onChange: (selectedRowKeys, selectedRows) => this.setState({ selectedScopes: selectedRowKeys })
                            }}
                            size={"small"}
                            bordered={true}
                            pagination={true}
                            loader={{
                                rows: 8,
                                component: <Skeleton active loading title={false} />,
                            }}
                            columns={this.usersScopeColumns}
                            dataSource={permissionStore.usersScopes}
                            rowKey={(record) => record.id}
                        />
                    </Col>
                    <Col span={24}>
                        <Button key="ok" type="primary"
                            disabled={this.state.selectedScopes.length === 0 || this.state.selectedUsers.length === 0}
                            nothing={permissionStore.permissionFormState?.loading ? true : false}
                            loading={this.state.loading}
                            style={{ width: "100%" }}
                            onClick={this.saveUserScopes}
                        >
                            Αποθήκευση
                        </Button>
                    </Col>
                </Row>
                <Divider style={{ marginTop: '10%' }} />
            </>
        )
    }
}


export default observer(UserPemrissionPage)


import Keycloak from "keycloak-js"
import { KEYCLOAK_CONFIG } from "../config"
import authStore from "../stores/authStore"

const KC = new Keycloak(KEYCLOAK_CONFIG)

export const initializeKeycloak = () => {
    KC.init({ onLoad: "login-required", checkLoginIframe: false }).then(
        (authenticated) => {
            if (authenticated) {
                localStorage.setItem("kc_token", KC.token)
                onLoadUser()
            }
        }
    )
}

export const onUpdateTokenWithKeycloak = async () => {
    KC.updateToken(30)
        .then(() => {
            localStorage.setItem("kc_token", KC.token)
        })
        .error((error) => {
            console.log(error)
        })
}

export const onLoadUser = async () => {
    return KC
        .loadUserProfile()
        .then((profile) => {
            localStorage.setItem("user", JSON.stringify(profile))
            localStorage.setItem("kc_token", KC.token)
            authStore.setUser(profile)
            authStore.setRealmRoles(KC.realmAccess ? KC.realmAccess.roles : [])
        })
        .catch((error) => {
            console.log(error)
        })
}

export const onLogoutWithKeycloak = () => {
    KC.logout({ redirectUri: window.location.origin + "/" })
}

export const onLoginWithKeycloak = () => {
    KC.login({ redirectUri: window.location.origin + "/" })
}

KC.onAuthLogout = function () {
    localStorage.removeItem("kc_token")
}

KC.onAuthSuccess = function () {
    localStorage.setItem("kc_token", KC.token)
    onLoadUser()
}

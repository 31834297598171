import React from "react"
import TableWithLoader from "antd-table-loader"
import userStore from "../../stores/userStore"
import { Divider, Input, Row, Skeleton } from "antd"
import { observer } from "mobx-react"
import { v4 } from "uuid"
import meStore from "../../stores/meStore"
import OrganizationUserDetailsModel from "../../components/OrganizationUserDetailModal"

class OrganizationUsersPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: "",
      selectedUser: null
    }
  }

  componentDidMount() {
    meStore.getManagedOrganizationUsers()
  }

  columns = [
    {
      title: "Α.Φ.Μ.",
      align: "center",
      render: (record) => `${record.attributes.tax_number[0]}`,
      key: "tax_number",
    },
    {
      title: "Ονόμα χρήστη",
      align: "center",
      render: (record) => `${record.username ? record.username : "Δ.Υ."}`,
      key: "tax_number",
    },
    {
      title: "Ονοματεπώνυμο",
      align: "center",
      render: (record) =>
        `${record?.firstName ? record?.firstName : "Δ.Υ."} ${record?.lastName ? record?.lastName : "Δ.Υ."}`,
      key: "name",
    },
    {
      title: "email",
      key: "email",
      align: "center",
      responsive: ["lg"],
      render: (record) => (record.email ? record.email : "Δ.Υ."),
    },
  ]

  onSearch = (e) => {
    const searchTerm = e.target.value
    this.setState({ searchText: searchTerm })
    meStore.onUserSearch(searchTerm)
  }

  render() {
    return (
      <>
        {this.state.selectedUser ? (
          <OrganizationUserDetailsModel
            close={() => this.setState({ selectedUser: null })}
            user={this.state.selectedUser}
          />
        ) : (
          <></>
        )}

        <Divider>Χρήστες Οργανισμού</Divider>
        <Row align={"center"} style={{ marginTop: "2%", marginBottom: "2%" }}>
          <Input.Search
            style={{ width: "40%", textAlign: "center" }}
            ref={(ele) => (this.searchText = ele)}
            onChange={this.onSearch}
            placeholder="Αναζήτηση με βάση το Α.Φ.Μ. ή το ονοματεπώνυμο."
            value={this.state.searchText}
            onPressEnter={this.onSearch}
          />
        </Row>
        <TableWithLoader
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 8,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={
            this.state.searchText
              ? meStore.filteredManagedOrganizationUsers
              : meStore.managedOrganizationUsers
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                this.setState({ selectedUser: record })
              },
            }
          }}
          rowKey={(record) =>
            Object.keys(record).length === 0 ? v4() : record.id
          }
        />
      </>
    )
  }
}

export default observer(OrganizationUsersPage)

import axios from "axios";
import { API_URL, DEFAULT_REALM } from "../config";
import clientStore from "../stores/clientStore";
import axiosRetry from "axios-retry";
import authStore from "../stores/authStore";

const MAX_RETRY_ATTEMPTS = 2;

export const unauthorizedApi = axios.create({
  baseURL: API_URL,
});

export const api = axios.create({
  baseURL: `${API_URL}/realms/${DEFAULT_REALM}/`,
});

export const basicApi = axios.create({
  baseURL: `${API_URL}/`,
});

export const apiClient = () => {
  const instance = axios.create({
    baseURL: `${API_URL}/realms/${DEFAULT_REALM}/clients/${clientStore.getClient()}`,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("kc_token")
      config.headers.Authorization = `Bearer ${token}`

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  )

  axiosRetry(instance, {
    retries: MAX_RETRY_ATTEMPTS,
    retryDelay: (retryCount) => {
      console.log(`Retry attempt: ${retryCount}`);
      return retryCount * 2000;
    },
    retryCondition: (error) => {
      return error.response.status === 500;
    },
  });
  return instance;
};

axiosRetry(api, {
  retries: MAX_RETRY_ATTEMPTS,
  retryDelay: (retryCount) => {
    console.log(`Retry attempt: ${retryCount}`);
    return retryCount * 2000;
  },
  retryCondition: (error) => {
    return error.response.status === 500;
  },
});


api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("kc_token")
    config.headers.Authorization = `Bearer ${token}`

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

basicApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("kc_token")
    config.headers.Authorization = `Bearer ${token}`

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
)

basicApi.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
)

const responseSuccessHandler = (response) => {
  return response
}

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    // authStore.forceLogout()
    return Promise.reject(error)
  }

  return Promise.reject(error)
}
import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"
import { notification } from "antd"
import { hasBadCharachters } from "../utils/generic"

class UserStore {
    filteredUsers = []
    users = null
    usersOptions = []
    userFormState = {}

    onSearch = (s) => {
        const text = s

        if (hasBadCharachters(text)) {
            return
        }

        const reg = new RegExp(text, "gi")

        const filteredData = this.users
            .map((user) => {
                const taxNumberMatch = user.taxNumber?.match(reg)
                const fullName = `${user?.firstName} ${user?.lastName}`
                const fullNameReverse = `${user?.lastName} ${user?.firstName}`

                const fullNameMatch = fullName.match(reg)
                const fullNameReverseMatch = fullNameReverse.match(reg)

                if (!taxNumberMatch && !fullNameMatch && !fullNameReverseMatch) {
                    return null
                }

                return user
            })
            .filter((record) => !!record)


        this.filteredUsers = filteredData
    }

    cleanUserFormState = () => {
        this.userFormState = {}
    }

    convertToOptions = (users) => {
        const usersOptions = []
        users.forEach((user) => {
            usersOptions.push({
                value: user.id,
                name: user.username
            })
        })

        this.usersOptions = usersOptions
    }

    constructor() {
        makeObservable(this, {
            users: observable,
            userFormState: observable.deep,
            usersOptions: observable,

            getUsers: action,
            createUser: action,
            createUserByFile: action,
        })
    }

    getUsers = () => {
        api
            .get("users")
            .then((response) => {
                this.users = response.data.map(obj => {
                    // Append to id the email beacuse the user has not id neither taxNumber
                    return {
                        ...obj,
                        id: obj.email
                    }
                })
                this.convertToOptions(this.users)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    createUser = (user) => {
        api
            .post("users", user)
            .then((response) => {
                this.userFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Ο χρήστης αποθηκέυτηκε",
                })
                this.getUsers()
            })
            .catch((error) => {
                console.log(error)
                this.resourceFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }

    createUserByFile = (file, onSuccess, onError, onProgress) => {
        const formData = new FormData()
        formData.append("file", file)

        api
            .post("users/file",
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        onProgress({ percent })
                    },
                }
            )
            .then((response) => {
                onSuccess()
                this.userFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Οι χρήστες αποθηκεύτηκαν",
                })
                this.getUsers()
            })
            .catch((error) => {
                onError(error)
                this.resourceFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }
}

const userStore = new UserStore()
export default userStore

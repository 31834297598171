import meStore from "../stores/meStore"
import permissionStore from "../stores/permissionStore"
import resourceStore from "../stores/resourceStore"


export const resetStoreOnClientChange = () => {
    permissionStore.resetStore()
    resourceStore.resetStore()
}

export const resetStorageOnOrganizationChange = () => {
    meStore.resetStore()
}
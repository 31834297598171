import { Button, Card, Col, Descriptions, Divider, Modal, Row, Upload } from "antd"
import React from "react"
import clientStore from "../stores/clientStore"

class ClientDetailsModel extends React.Component {

    componentDidUpdate() {
        if (clientStore.clientFormState?.success) {
            this.setState({
                form: {},
                loading: clientStore.clientFormState?.loading,
            })
            clientStore.cleanClientFormState()
        }

        if (clientStore.clientFormState?.success == false) {
            this.setState({
                loading: clientStore.clientFormState?.loading,
            })
            clientStore.cleanClientFormState()
        }
    }

    customRequestImplementation = async ({
        file,
        onSuccess,
        onError,
        onProgress,
    }) => {
        clientStore.massiveSetupByFile(
            file, this.props.client.clientId, onSuccess, onError, onProgress
        )
    }

    renderMassiveUpload = () => {
        return (
            <Card
                style={{ marginTop: "24px", height: "100%" }}
            >

                <p>
                    Παραμετροποίηση εφαρμογής μέσω αρχείου.
                </p>

                <Upload style={{ width: "100%" }}

                    {...{
                        showUploadList: {
                            showDownloadIcon: false,
                            showRemoveIcon: false,
                            previewIcon: false,
                        },
                        customRequest: this.customRequestImplementation,
                        onChange: (info) => {
                            console.log(info)
                        },
                    }}
                >
                    <Button key="ok" type="primary" style={{ width: "100%" }}>
                        Ανέβασμα αρχείου JSON
                    </Button>
                </Upload>
            </Card>
        )
    }

    renderClientDetails = () => {
        return (
            <>
                <Card
                    style={{ marginTop: "24px", height: "100%" }}
                >

                    <Descriptions
                        layout="horizontal"
                        items={[
                            {
                                key: "1",
                                label: "ID",
                                span: 24,
                                children: <b>{this.props.client.clientId}</b>,
                            },
                            {
                                key: "2",
                                label: "Ονομασία",
                                span: 24,
                                children: <b>{this.props.client.name}</b>,
                            },
                            {
                                key: "3",
                                label: "Περιγραφή",
                                span: 24,
                                children: <b>{this.props.client.description}</b>,
                            },
                        ]}
                    />
                </Card>
            </>
        )
    }

    render() {
        return (
            <>
                <Modal
                    width={"70%"}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.props.close}>
                            Πίσω
                        </Button>,
                    ]}
                    open={true}
                    onCancel={() => this.props.close()}
                    onOk={() => this.props.close()}
                >
                    <Divider>
                        Εφαρμογή
                    </Divider>

                    <Row gutter={[50, 50]}>
                        <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                            <div style={{ flex: 1, marginBottom: "16px" }}>
                                {this.renderClientDetails()}
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                            <div style={{ flex: 1, marginBottom: "16px" }}>
                                {this.renderMassiveUpload()}
                            </div>
                        </Col>
                    </Row>


                </Modal>
            </>
        )
    }
}

export default ClientDetailsModel
import React from "react"
import TableWithLoader from "antd-table-loader"
import userStore from "../../stores/userStore"
import { Divider, Input, Row, Skeleton } from "antd"
import { observer } from "mobx-react"
import { v4 } from "uuid"

class UsersPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: ""
    }
  }

  componentDidMount() {
    userStore.getUsers()
  }

  columns = [
    {
      title: "Α.Φ.Μ.",
      align: "center",
      render: (record) => `${record.tax_number}`,
      key: "tax_number",
    },
    {
      title: "Ονόμα χρήστη",
      align: "center",
      render: (record) => `${record.username ? record.username : "Δ.Υ."}`,
      key: "tax_number",
    },
    {
      title: "Ονοματεπώνυμο",
      align: "center",
      render: (record) =>
        `${record?.firstName ? record?.firstName : "Δ.Υ."} ${record?.lastName ? record?.lastName : "Δ.Υ."}`,
      key: "name",
    },
    {
      title: "email",
      key: "email",
      align: "center",
      responsive: ["lg"],
      render: (record) => (record.email ? record.email : "Δ.Υ."),
    },
  ]

  onSearch = (e) => {
    const searchTerm = e.target.value
    this.setState({ searchText: searchTerm })
    userStore.onSearch(searchTerm)
  }

  render() {
    return (
      <>
        <Divider>Χρήστες</Divider>
        <Row align={"center"} style={{ marginTop: "2%", marginBottom: "2%" }}>
          <Input.Search
            style={{ width: "40%", textAlign: "center" }}
            ref={(ele) => (this.searchText = ele)}
            onChange={this.onSearch}
            placeholder="Αναζήτηση με βάση το Α.Φ.Μ. ή το ονοματεπώνυμο."
            value={this.state.searchText}
            onPressEnter={this.onSearch}
          />
        </Row>
        <TableWithLoader
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 8,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={
            this.state.searchText
              ? userStore.filteredUsers
              : userStore.users
          }
          rowKey={(record) =>
            Object.keys(record).length === 0 ? v4() : record.id
          }
        />
      </>
    )
  }
}

export default observer(UsersPage)

import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"
import { message } from "antd"
import { covnertJsonToXlsxtAndDownloadTheFile } from "../utils/xlsx"
import { DATETIME_FORMAT } from "../config"

class ReportStore {
    sessions = []

    loading = false

    constructor() {
        makeObservable(this, {
            sessions: observable,
            loading: observable,

            getSessions: action,
            setLoading: action,
            downloadSessionsToXlsx: action
        })
    }

    setLoading = (isLoading) => {
        this.loading = isLoading
    }

    getSessions = () => {
        api
            .get("sessions")
            .then((response) => {
                this.sessions = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    downloadSessionsToXlsx = (from, to) => {
        api
            .get(`sessions${from && to ? `?from=${from.unix()}&to=${to.unix()}` : ""}`)
            .then((response) => {
                this.sessions = response.data
                const filename = from && to ? `${from.format(DATETIME_FORMAT)}-${to.format(DATETIME_FORMAT)}-sessions.xlsx` : "sessions.xlsx"
                covnertJsonToXlsxtAndDownloadTheFile(
                    this.sessions,
                    filename,
                    "Sessions 1"
                )
                this.setLoading(false)
                message.success('Η εξαγωγή ολοκληρώθηκε')
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

const reportStore = new ReportStore()
export default reportStore

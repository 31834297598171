import { Col, Drawer, Row } from "antd"
import React from "react"
import Manual from "./Manual"

class ManualDrawer extends React.PureComponent {

    constructor(props) {
        super(props)
    }

    renderManuals = () => {
        return this.props.manuals?.map((manual, index) =>
            <Row align={'center'} key={`manual-${index}`} style={{ marginTop: '5%' }}>
                <Col span={24}>
                    <Manual manual={manual} />
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <Drawer
                title={"Εγχειρίδια χρήσης"}
                placement={"right"}
                closable={true}
                open={true}
                onClose={() => this.props.close()}
            >
                {this.renderManuals()}
            </Drawer>
        )
    }
}

export default ManualDrawer
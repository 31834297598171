import { Button, Card, Col, Descriptions, Divider, Modal, Row, Skeleton, Upload } from "antd"
import React from "react"
import permissionStore from "../stores/permissionStore"
import TableWithLoader from "antd-table-loader"
import { observer } from "mobx-react"

class OrganizationUserDetailsModel extends React.Component {

    constructor(props) {
        super(props)
        permissionStore.getIndividualUserPermissions(props.user.id)
    }


    usersScopeColumns = [
        {
            title: "Περιορισμοί",
            align: "center",
            render: (record) => record,
            key: "name",
        },
    ]

    usersRoleColumns = [
        {
            title: "Ρόλοι",
            align: "center",
            render: (record) => record,
            key: "name",
        },
    ]

    renderUserDetails = () => {
        return (
            <>
                <Card
                    style={{ marginTop: "24px", height: "100%" }}
                >

                    <Descriptions
                        layout="horizontal"
                        items={[
                            {
                                key: "1",
                                label: "Α.Φ.Μ.",
                                span: 1,
                                children: <b>{this.props.user.attributes.tax_number[0]}</b>,
                            },
                            {
                                key: "2",
                                label: "email",
                                span: 1,
                                children: <b>{this.props.user.email}</b>,
                            },
                            {
                                key: "2",
                                label: "username",
                                span: 1,
                                children: <b>{this.props.user.username}</b>,
                            },
                        ]}
                    />
                </Card>
            </>
        )
    }

    render() {
        return (
            <>
                <Modal
                    loading={permissionStore.individualUserPermissions == null}
                    width={"70%"}
                    footer={[
                        <Button key="ok" type="primary" onClick={this.props.close}>
                            Πίσω
                        </Button>,
                    ]}
                    open={true}
                    onCancel={() => {
                        permissionStore.cleanIndividualUserPermissions()
                        this.props.close()
                    }}
                    onOk={() => {
                        permissionStore.cleanIndividualUserPermissions()
                        this.props.close()
                    }}>
                    <Divider>
                        {this.props.user.firstName} {this.props.user.lastName}
                    </Divider>

                    <Col style={{ display: "flex", flexDirection: "column" }} span={24}>
                        <div style={{ flex: 1, marginBottom: "16px" }}>
                            {this.renderUserDetails()}
                        </div>
                    </Col>

                    <Row gutter={[25, 25]}>
                        <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                            <div style={{ flex: 1, marginBottom: "16px" }}>
                                <TableWithLoader
                                    size={"small"}
                                    bordered={true}
                                    pagination={true}
                                    loader={{
                                        rows: 8,
                                        component: <Skeleton active loading title={false} />,
                                    }}
                                    columns={this.usersScopeColumns}
                                    dataSource={permissionStore.individualUserPermissions?.scopes}
                                    rowKey={(record) => record.id}
                                />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
                            <div style={{ flex: 1, marginBottom: "16px" }}>
                                <TableWithLoader
                                    size={"small"}
                                    bordered={true}
                                    pagination={true}
                                    loader={{
                                        rows: 8,
                                        component: <Skeleton active loading title={false} />,
                                    }}
                                    columns={this.usersRoleColumns}
                                    dataSource={permissionStore.individualUserPermissions?.roles}
                                    rowKey={(record) => record.id}
                                />
                            </div>
                        </Col>
                    </Row>



                </Modal>
            </>
        )
    }
}

export default observer(OrganizationUserDetailsModel)
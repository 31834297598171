import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import React, { Component } from "react";
import { observer } from "mobx-react";
import organizationStore from "../../stores/organizationStore";
import authStore from "../../stores/authStore";
import { v4 } from "uuid";

const { TextArea } = Input;

const { Option } = Select;

class CreateOrgPage extends Component {
  formRef = React.createRef()

  constructor(props) {
    super(props);

    this.state = {
      form: {},
      loading: false,
    };
  }

  componentDidMount() {
    organizationStore.getRegionalUnits();
    organizationStore.getOrganizations();

  }

  onSubmitPressed = () => {
    this.setState({ loading: true })
    let orgData = {
      organization: "e7e4c98b-6eae-4864-8b66-1415073ca639",
      // organization: authStore.userId,

      ...this.state.form,
    };

    organizationStore.createOrganizationRequest(orgData);
  };

  componentDidUpdate() {
    if (organizationStore.organizationFormState?.success) {
      this.setState({
        form: {},
        loading: organizationStore.organizationFormState?.loading,
      })
      organizationStore.cleanOrganizationFormState()
      this.formRef.current.resetFields()
    }

    if (organizationStore.organizationFormState?.success == false) {
      this.setState({
        loading: organizationStore.organizationFormState?.loading,
      })
      organizationStore.cleanOrganizationFormState()
    }
  }

  renderForm = () => {
    return (
      <>
        <Form ref={this.formRef} layout="vertical">
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <Form.Item
                name={"organization_type"}
                label={"Tύπος Φορέα"}
                rules={[
                  {
                    message: "Παρακαλώ συμπληρώστε το τύπο φορέα",
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        organization_type: value,
                      },
                    });
                  }}
                  placeholder=""
                  disabled={false}
                >
                  {organizationStore.organizations.map((organization) => (
                    <Option key={organization.code} value={organization.id}>
                      {organization.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"name"}
                label={"Eπωνυμία Νομικού Προσώπου"}
                rules={[
                  {
                    message: "Παρακαλώ συμπληρώστε την επωνυμία",
                    required: true,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        name: e.target.value,
                      },
                    });
                  }}
                  placeholder={""}
                  disabled={false}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"tax_number"}
                label={"ΑΦΜ"}
                rules={[
                  {
                    required: true,
                    message: "Παρακαλώ εισάγετε τον ΑΦΜ σας",

                  },
                  {
                    pattern: /^\d+$/,
                    message: "Παρακαλώ εισάγετε μόνο αριθμούς",
                  },
                  {
                    validator: (_, value) =>
                      value && value.toString().length === 9
                        ? Promise.resolve()
                        : Promise.reject(new Error("Ο ΑΦΜ πρέπει να είναι ακριβώς 9 ψηφία")),
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        tax_number: e.target.value,
                      },
                    });
                  }}
                  placeholder={""}
                  disabled={false}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"email"}
                label={"Email"}
                rules={[
                  {
                    type: "email",
                    message: "Παρακαλώ συμπληρώστε ένα έγκυρο Email",
                    required: true,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        email: e.target.value,
                      },
                    });
                  }}
                  placeholder={""}
                  disabled={false}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"phone_number"}
                label={"Τηλέφωνο"}
                rules={[
                  {
                    message: "Παρακαλώ συμπληρώστε ένα έγκυρο Τηλέφωνο",
                    required: true,
                  },
                  {
                    pattern: /^\d+$/,
                    message: "Παρακαλώ εισάγετε μόνο αριθμούς",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        phone_number: e.target.value,
                      },
                    });
                  }}
                  placeholder={""}
                  disabled={false}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={"regional_unit"}
                label={"Περιφερειακή Ενότητα"}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  onChange={(value) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        regional_unit: value,
                      },
                    });
                  }}
                  placeholder=""
                  disabled={false}
                >
                  {organizationStore.regionalUnits.map((regionalUnit) => (
                    <Option key={regionalUnit.code} value={regionalUnit.code}>
                      {regionalUnit.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={"audit_remarks"}
                label={"Σχόλια Αιτών"}
                rules={[
                  {
                    required: false,
                  },
                  {
                    max: 500,
                    message: "Το σχόλιο δεν πρέπει να ξεπερνά τα 500 χαρακτήρες",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  onChange={(e) => {
                    this.setState({
                      form: {
                        ...this.state.form,
                        audit_remarks: e.target.value,
                      },
                    });
                  }}
                  placeholder={""}
                  disabled={false}
                />
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </>
    );
  };

  render() {
    return (
      <div style={{ marginBottom: "10%" }}>
        <Divider>Δημιουργία Φορέα</Divider>

        {this.renderForm()}
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            key="ok"
            type="primary"
            nothing={organizationStore.organizationFormState?.loading ? true : false}
            loading={this.state.loading}
            style={{ width: "100%" }}
            onClick={() => this.onSubmitPressed()}
          >
            Yποβολή Αίτησης
          </Button>
          <Button
            loading={this.state.loading}
            style={{ width: "100%" }}
            onClick={() => organizationStore.organizationFormState = {
              success: true,
              loading: false,
            }}
          >
            Kαθαρισμός Φόρμας
          </Button>
        </div>
      </div >
    );
  }
}

export default observer(CreateOrgPage);

import { observable, action, makeObservable } from "mobx"
import { api } from "../api/api"
import organizationStore from "./organizationStore"
import { resetStorageOnOrganizationChange } from "../utils/storeActions"
import { hasBadCharachters } from "../utils/generic"

class MeStore {
    managedOrganizations = []
    managedOrganizationsOptions = []

    managedOrganizationUsers = []
    filteredManagedOrganizationUsers = []

    selectedOrganization = null

    onUserSearch = (s) => {
        const text = s

        if (hasBadCharachters(text)) {
            return
        }

        const reg = new RegExp(text, "gi")

        const filteredData = this.managedOrganizationUsers
            .map((user) => {
                const taxNumberMatch = user.attributes.tax_number[0]?.match(reg)
                const fullName = `${user?.firstName} ${user?.lastName}`
                const fullNameReverse = `${user?.lastName} ${user?.firstName}`

                const fullNameMatch = fullName.match(reg)
                const fullNameReverseMatch = fullNameReverse.match(reg)

                if (!taxNumberMatch && !fullNameMatch && !fullNameReverseMatch) {
                    return null
                }

                return user
            })
            .filter((record) => !!record)


        this.filteredManagedOrganizationUsers = filteredData
    }

    constructor() {
        makeObservable(this, {
            managedOrganizations: observable,
            managedOrganizationUsers: observable,
            filteredManagedOrganizationUsers: observable,
            selectedOrganization: observable,
            managedOrganizationsOptions: observable,

            setOrganization: action,
            getOrganization: action,
            getManagedOranizations: action,
            getManagedOrganizationUsers: action,
        })
    }

    resetStore = () => {
        this.managedOrganizationUsers = []
        this.getManagedOrganizationUsers(this.selectedOrganization)
    }

    setOrganization = (organizationId) => {
        if (organizationId == null) return null
        this.selectedOrganization = organizationId
        localStorage.setItem('organizationId', organizationId)

        resetStorageOnOrganizationChange()
    }

    getSelectedOrganizationInstanceId = () => {
        const selectedOrganizationInstance = this.managedOrganizations.find(organization =>
            organization.organization_id == this.getOrganization()
        )

        return selectedOrganizationInstance?.organization_instance_id
    }

    getSelectedOrganizationInstanceTitle = () => {
        const selectedOrganizationInstance = this.managedOrganizations.find(organization =>
            organization.organization_id == this.getOrganization()
        )

        return selectedOrganizationInstance?.organization_name
    }

    getOrganization = () => {
        if (this.selectedOrganization) {
            return this.selectedOrganization
        }

        const storageOrganization = localStorage.getItem('organizationId')
        if (storageOrganization) {
            this.setOrganization(storageOrganization)
            return storageOrganization
        }

        if (this.managedOrganizations?.length > 0) {
            const defaultManagedOrganization = this.managedOrganizations[0].organization_id
            this.setOrganization(defaultManagedOrganization)
            return defaultManagedOrganization
        }

        return null
    }

    getManagedOrganizationUsers = (organizationId) => {
        api
            .get(`me/organizations/managed/${organizationId}/users`)
            .then((response) => {
                this.managedOrganizationUsers = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getManagedOranizations = () => {
        api
            .get("me/organizations/managed")
            .then((response) => {
                this.managedOrganizations = response.data.map(org => {
                    return {
                        ...org, ...this.mapOrganzationInstanceToOrganization(org.organization_id)
                    }
                })
                this.mapOrganizationsToOptions(this.managedOrganizations)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    mapOrganizationsToOptions = (organizations) => {
        const managedOrganizationsOptions = []
        organizations.forEach((organization) => {
            managedOrganizationsOptions.push({
                value: organization.id,
                name: organization.title,
            })
        })

        this.managedOrganizationsOptions = managedOrganizationsOptions
    }

    mapOrganzationInstanceToOrganization = (organizationId) => {
        return organizationStore.organizations.find(org => org.id === organizationId)
    }
}

const meStore = new MeStore()
export default meStore

/**
 * Json snippets to be used as an example at bulk creation via .json file
 */


export const exampleRoles = [
    {
        "name": "farmer",
        "description": "Δικαιούχος",
        "is_active": true
    },
    {
        "name": "opekepe_admin",
        "description": "Διαχειριστής ΟΠΕΚΕΠΕ",
        "is_active": true
    },
    {
        "name": "opekepe_employee",
        "description": "Υπάλληλος ΟΠΕΚΕΠΕ",
        "is_active": true
    },
    {
        "name": "kyd_employee",
        "description": "Υπάλληλος ΚΥΔ",
        "is_active": true
    }
]

export const exampleUsers = [
    {
        "firstName": "Ναπολέων",
        "lastName": "Κατεβάτης",
        "email": "napoleon.katebates@example.com",
        "tax_number": "980870872",
        "submission_portal_id": 604,
        "daok_id": null,
        "group": "kyd"
    },
    {
        "firstName": "Θεόφραστος",
        "lastName": "Θηβαίος",
        "email": "theophrastos.thebaios@example.com",
        "tax_number": "272776138",
        "submission_portal_id": 604,
        "daok_id": null,
        "group": "kyd"
    },
    {
        "firstName": "Ματίνα",
        "lastName": "Κλεινάκη",
        "email": "matina.kleinake@example.com",
        "tax_number": "510304597",
        "submission_portal_id": 604,
        "daok_id": null,
        "group": "kyd"
    },
    {
        "firstName": "Φραντζέσκα",
        "lastName": "Κιοσές",
        "email": "phrantzeska.kioses@example.com",
        "tax_number": "466644092",
        "submission_portal_id": 604,
        "daok_id": null,
        "group": "kyd"
    },
    {
        "firstName": "Οδυσσεύς",
        "lastName": "Πατσούρας",
        "email": "odusseus.patsouras@example.com",
        "tax_number": "261585282",
        "submission_portal_id": 604,
        "daok_id": null,
        "group": "kyd"
    }
]

export const examplePermissions = [
    {
        "name": "aade_aadeownedatakregistry_add",
        "description": "Δυνατότητα δημιουργίας/καταχώρησης στο resource  ΑΑΔΕ - Στοιχεία ιδιόκτητων ΑΤΑΚ"
    },
    {
        "name": "aade_aadeownedatakregistry_change",
        "description": "Δυνατότητα τροποποίησης στο resource  ΑΑΔΕ - Στοιχεία ιδιόκτητων ΑΤΑΚ"
    },
    {
        "name": "aade_aadeownedatakregistry_delete",
        "description": "Δυνατότητα διαγραφής στο resource  ΑΑΔΕ - Στοιχεία ιδιόκτητων ΑΤΑΚ"
    },
    {
        "name": "aade_aadeownedatakregistry_view",
        "description": "Δυνατότητα προβολής/ανάγνωσης στο resource  ΑΑΔΕ - Στοιχεία ιδιόκτητων ΑΤΑΚ"
    }
]

export const exampleResources = [
    {
        "method": "GET",
        "endpoint": "/eae/api/v1/applications/overview/",
        "requiredPermissions": ["eae_application_view"],
        "name": "Αναζήτηση αιτήσεων"
    },
    {
        "method": "GET",
        "endpoint": "/eae/api/v1/applications/gis/search/",
        "requiredPermissions": ["eae_application_view"],
        "name": "Αναζήτηση αγροτεμαχίων βάση στοιχείων δικαιούχου"
    },
    {
        "method": "POST",
        "endpoint": "/eae/api/v1/applications/reload/",
        "requiredPermissions": ["eae_application_add"],
        "name": "Δημιουργία πρόχειρης αίτησης από οριστική αίτηση του προηγούμενου έτους ΕΑΕ"
    },
    {
        "method": "GET",
        "endpoint": "/eae/api/v1/applications/:application_id/",
        "requiredPermissions": ["eae_application_view"],
        "name": "Αναζήτηση αίτησης"
    }
]



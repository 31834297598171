import {
  Descriptions,
  Select,
  Skeleton,
} from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import clientStore from '../stores/clientStore'
import authStore from '../stores/authStore'
import { DEFAULT_REALM } from '../config'
import meStore from '../stores/meStore'
import organizationStore from '../stores/organizationStore'
import { UserRole } from '../utils/userRole'

class Header extends React.Component {
  componentDidMount() {
    organizationStore.getOrganizations()
    clientStore.getClients()
    meStore.getManagedOranizations()
  }

  renderOrganizationTypes = () => {
    if (organizationStore.organizations?.length == 0 || meStore.getOrganization() == null || meStore.managedOrganizationsOptions?.length == 0) {
      return <Skeleton active loading title={true} paragraph={false} />
    }

    return (
      <>
        <Select
          onChange={(value) => meStore.setOrganization(value)}
          defaultValue={meStore.getOrganization()}
          style={{ width: '100%' }}
        >
          {meStore.managedOrganizationsOptions?.map((option) => {
            return (
              <>
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              </>
            )
          })}
        </Select>
      </>
    )
  }

  renderOrganizationInstances = () => {
    return <>{meStore.getSelectedOrganizationInstanceTitle()}</>
  }

  renderClients = () => {
    if (clientStore.getClient() == null || clientStore.clientsOptions?.length == 0) {
      return <Skeleton active loading title={true} paragraph={false} />
    }

    return (
      <>
        <Select
          onChange={(value) => clientStore.setClient(value)}
          defaultValue={clientStore.getClient()}
          style={{ width: '100%' }}
        >
          {clientStore.clientsOptions?.map((option) => {
            return (
              <>
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              </>
            )
          })}
        </Select>
      </>
    )
  }

  renderRole = () => {
    return <>{authStore.getRoleText()}</>
  }

  render() {
    let items = []
    switch (true) {
      case (
        this.props.selectedMenu == "role-menu" ||
        this.props.selectedMenu == "user-menu" ||
        this.props.selectedMenu == "reports-menu" ||
        authStore.role == UserRole.NO_ROLE
        || !this.props.selectedMenu):
        items = [
          {
            key: 'user',
            label: 'Χρήστης',
            children: `${authStore.firstName} ${authStore.lastName}`,
            span: 2,
          },
          {
            key: 'role',
            label: 'Ρόλος',
            children: this.renderRole(),
            span: 2,
          },
          {
            key: 'realm',
            label: 'Realm',
            children: DEFAULT_REALM,
            span: 2,
          },
        ]
        break
      case (this.props.selectedMenu == "permission-menu" || this.props.selectedMenu == "resource-menu"):
        items = [
          {
            key: 'user',
            label: 'Χρήστης',
            children: `${authStore.firstName} ${authStore.lastName}`,
            span: 2,
          },
          {
            key: 'role',
            label: 'Ρόλος',
            children: this.renderRole(),
            span: 2,
          },
          {
            key: 'realm',
            label: 'Realm',
            children: DEFAULT_REALM,
            span: 2,
          },
          {
            key: 'client',
            label: 'Εφαρμογή',
            children: this.renderClients(),
            span: 2,
          },
        ]
        break
      default:
        items = [
          {
            key: 'user',
            label: 'Χρήστης',
            children: `${authStore.firstName} ${authStore.lastName}`,
            span: 2,
          },
          {
            key: 'role',
            label: 'Ρόλος',
            children: this.renderRole(),
            span: 2,
          },
          {
            key: 'realm',
            label: 'Realm',
            children: DEFAULT_REALM,
            span: 2,
          },
          {
            key: 'client',
            label: 'Εφαρμογή',
            children: this.renderClients(),
            span: 2,
          },
          {
            key: 'organization',
            label: 'Τύπος φορέα',
            children: this.renderOrganizationTypes(),
            span: 2,
          },
          {
            key: 'organization_instance',
            label: 'Επωνυμία φορέα',
            children: this.renderOrganizationInstances(),
            span: 2,
          },
        ]
        break
    }

    return (
      <>
        <Descriptions style={{ width: '100%' }} items={items} bordered={true} />
      </>
    )
  }
}

export default observer(Header)

import { observable, action, makeObservable } from "mobx";
import { api, basicApi } from "../api/api";
import { notification } from "antd";
import { hasBadCharachters } from "../utils/generic"

class OrganizationStore {
  filteredRequests = []
  regionalUnits = []
  organizations = []
  organizationFormState = {}
  organizationRequests = []

  onSearch = (s) => {
    const text = s

    if (hasBadCharachters(text)) {
      return
    }

    const reg = new RegExp(text, "gi")

    const filteredData = this.organizationRequests
      .map((request) => {
        const nameMatch = request.name?.match(reg)
        const taxNumberMatch = request.tax_number?.match(reg)
        const applicantTaxNumberMatch = request.applicant_tax_number?.match(reg)


        if (!taxNumberMatch && !nameMatch && !applicantTaxNumberMatch) {
          return null
        }

        return request
      })
      .filter((record) => !!record)

    this.filteredRequests = filteredData
  }

  constructor() {
    makeObservable(this, {
      regionalUnits: observable,
      organizations: observable,
      organizationFormState: observable.deep,
      organizationRequests: observable,

      getOrganizations: action,
      getRegionalUnits: action,
      getOrganizationRequests: action,
    })
  }

  cleanOrganizationFormState = () => {
    this.organizationFormState = {}
  }

  getTitleById(id) {
    const foundObject = this.organizations.find(item => item.id === id)

    return foundObject ? foundObject.title : undefined
  }

  getRegionalUnitByCode(code) {
    const foundObject = this.regionalUnits.find(item => item.code === code);

    return foundObject ? foundObject.title : undefined
  }

  getOrganizations = () => {
    api
      .get("organizations")
      .then((response) => {
        this.organizations = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRegionalUnits = () => {
    api
      .get("regional-units")
      .then((response) => {
        this.regionalUnits = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createOrganizationRequest = (organization) => {
    api
      .post("organization-requests", organization)
      .then((response) => {
        this.organizationFormState = {
          success: true,
          loading: false,
        };
        notification.success({
          message: "Επιτυχία",
          description: "To αίτημα καταχωρήθηκε",
        })
        this.getOrganizationRequests()
      })
      .catch((error) => {
        console.log(error);
        this.resourceFormState = {
          success: false,
          loading: false,
        };
        notification.error({
          message: "Αποτυχία",
          description: "Κάτι πήγε λάθος",
        });
      });
  };

  getOrganizationRequests = () => {
    api
      .get("organization-requests")
      .then((response) => {
        this.organizationRequests = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateOrganizationRequests = (req_data) => {
    api.post("organization-request-reviews", req_data).catch((error) => {
      console.log(error);
    });
  };
}

const organizationStore = new OrganizationStore();
export default organizationStore;

import { observable, action, makeObservable } from "mobx"
import { api, apiClient } from "../api/api"
import { notification } from "antd"
import { hasBadCharachters } from "../utils/generic"

class ResourceStore {
    searchTerm = ""
    filteredResources = []
    resources = null
    resourceFormState = {}

    onSearch = (s) => {
        this.searchTerm = s
        const text = s

        if (hasBadCharachters(text)) {
            return
        }

        const reg = new RegExp(text, "gi")

        const filteredData = this.resources
            .map((resource) => {
                const urlMatch = resource.endpoint.match(reg)
                const nameMatch = resource.name.match(reg)
                const permissionMatch = resource.requiredPermissions.join(' ').match(reg)

                if (!urlMatch && !nameMatch && !permissionMatch) {
                    return null
                }

                return resource
            })
            .filter((record) => !!record)


        this.filteredResources = filteredData
    }

    resetStore = () => {
        this.resources = null
        this.resourceFormState = {}
        this.filteredResources = []
        this.searchTerm = ""
        this.getResources()
    }

    cleanResourceFormState = () => {
        this.resourceFormState = {}
    }

    constructor() {
        makeObservable(this, {
            resources: observable,
            resourceFormState: observable.deep,
            searchTerm: observable,

            getResources: action,
            createResource: action,
            createResourceByFile: action,

            resetStore: action
        })
    }

    getResources = () => {
        apiClient()
            .get("resources")
            .then((response) => {
                this.resources = response.data
            })
            .catch((error) => {
                console.log(error)
            })
    }

    createResource = (resource) => {
        apiClient()
            .post("resources", [resource])
            .then((response) => {
                this.resourceFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Ο πόρος αποθηκέυτηκε",
                })
                this.getResources()
            })
            .catch((error) => {
                this.resourceFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }

    createResourceByFile = (file, onSuccess, onError, onProgress) => {
        const formData = new FormData()
        formData.append("file", file)

        apiClient()
            .post("resources/file",
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        onProgress({ percent })
                    },
                }
            )
            .then((response) => {
                onSuccess()
                this.resourceFormState = {
                    success: true,
                    loading: false,
                }
                notification.success({
                    message: "Επιτυχία",
                    description: "Οι πόροι αποθηκεύτηκαν",
                })
                this.getResources()
            })
            .catch((error) => {
                onError(error)
                this.resourceFormState = {
                    success: false,
                    loading: false,
                }
                notification.error({
                    message: "Αποτυχία",
                    description: "Κάτι πήγε λάθος",
                })
            })
    }


    methods = [
        {
            value: "GET",
            name: "GET"
        },
        {
            value: "POST",
            name: "POST"
        },
        {
            value: "DELETE",
            name: "DELETE"
        },
        {
            value: "PUT",
            name: "PUT"
        },
        {
            value: "PATCH",
            name: "PATCH"
        },
    ]
}

const resourceStore = new ResourceStore()
export default resourceStore
